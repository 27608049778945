import { gql } from 'src/graphql/__generated__/gql';
import {
  DeleteAssetValueMutation,
  DeleteAssetValueMutationVariables,
} from 'src/graphql/__generated__/graphql';
import { GraphqlMutation } from 'src/util/graphql';

export interface IDeleteAssetValue {
  input: DeleteAssetValueMutationVariables;
  output: DeleteAssetValueMutation;
}

export const DeleteAssetValue: GraphqlMutation<IDeleteAssetValue, { assetValueId: string }> = {
  query: gql(/* GraphQL */ `
    mutation DeleteAssetValue($assetValueId: ID!) {
      deleteAssetValue(assetValueId: $assetValueId) {
        id
      }
    }
  `),
  optimisticResponse: ({ assetValueId }) => ({
    deleteAssetValue: {
      id: assetValueId,
    },
  }),
};
