import { gql } from '@apollo/client';
import { AccountType } from 'src/graphql/__generated__/graphql';

export const EDIT_ACCOUNT = gql`
  mutation EditAccount($editAccountArgs: EditAccountArgs!) {
    editAccount(editAccountArgs: $editAccountArgs) {
      id
      nickname
      type
      accountNumber
    }
  }
`;

export interface EditAccountInput {
  editAccountArgs: {
    id: string;
    accountNumber: string;
    nickname: string;
    type: AccountType;
  };
}

export interface EditAccountPayload {
  editAccount: {
    id: string;
    accountNumber: string;
    nickname: string;
    type: AccountType;
  };
}
