import { gql } from 'src/graphql/__generated__';
import {
  AccountType,
  CreateEmailAccountMutation,
  CreateEmailAccountMutationVariables,
} from 'src/graphql/__generated__/graphql';
import { GraphqlMutation } from 'src/util/graphql';

export interface ICreateEmailAccount {
  input: CreateEmailAccountMutationVariables;
  output: CreateEmailAccountMutation;
}

export const CreateEmailAccount: GraphqlMutation<
  ICreateEmailAccount,
  {
    id: string;
    nickname: string;
  }
> = {
  query: gql(/* GraphQL */ `
  mutation CreateEmailAccount($createAccountArgs: CreateEmailAccountArgs!) {
    createEmailAccount(createAccountArgs: $createAccountArgs) {
      id
      nickname
      type
    }
  }
`),
  optimisticResponse: ({ id, nickname }) => ({
    createEmailAccount: {
      id,
      nickname: nickname,
      type: AccountType.Unknown,
    },
  }),
  update: (cache, { data }, { variables }) => {
    if (data === undefined || data == null || variables === undefined) {
      return;
    }

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
  },
};
