import { Flex, Heading, Button, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { ChakraMotionBox } from './ChakraMotionBox';

interface EmptyListCtaProps {
  title: string;
  description: string;
  Icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttonText?: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export const EmptyListCta = ({
  title,
  description,
  Icon,
  buttonText,
  onClick,
  isLoading,
}: EmptyListCtaProps) => {
  return (
    <ChakraMotionBox initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Flex justify={'center'} alignSelf="center" gap={8}>
        <Icon width={100} height={100} style={{ alignSelf: 'flex-start' }} />
        <Flex flexDir={'column'} gap={4} maxW={'40ch'}>
          <Heading size={'md'}>{title}</Heading>
          <Text>{description}</Text>
          <Button
            size={'sm'}
            width="fit-content"
            onClick={onClick}
            isLoading={isLoading}
            hidden={onClick === undefined}
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </ChakraMotionBox>
  );
};
