import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModePreference,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { ChooseAccountType } from './ChooseAccountType';
import { CreateEditAccountForm } from './CreateEditAccountForm';
import { DotProgressIndicator } from 'src/components/shared/DotProgressIndicator';
import { CsvUploadForm, FieldMapping } from 'src/components/csvUpload/CsvUploadForm';
import { CreateAutomaticAccount } from './CreateAutomaticAccount';
import { gql, useMutation } from '@apollo/client';
import { IMPORT_STATEMENTS, importStatementsAction } from 'src/graphql/ImportStatements';
import { CsvFile } from 'src/components/csvUpload/FileSelect';
import { CREATE_MANUAL_ACCOUNT } from 'src/graphql/CreateManualAccount';
import { SetupAssetAccount } from 'src/components/overview/createAccountFlow/SetupAssetAccount';
import { AccountType, CreateManualAccountArgs } from 'src/graphql/__generated__/graphql';
import { SetupEmailAccount } from 'src/components/overview/createAccountFlow/emailAccount/SetupEmailAccount';
import { ForwardInstructionsEmailAccount } from 'src/components/overview/createAccountFlow/emailAccount/ForwardInstructionsEmailAccount';

interface CreateAccountModalProps {
  isOpen: boolean;
  onCloseModal: ReturnType<typeof useDisclosure>['onClose'];
}

export enum CreateAccountType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Asset = 'ASSET',
  Email = 'EMAIL',
}

export const CreateAccountModal = ({ isOpen, onCloseModal }: CreateAccountModalProps) => {
  const colorMode = useColorModePreference();
  const [plaidLinkOpen, setPlaidLinkOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [accountType, setAccountType] = useState<CreateAccountType>(CreateAccountType.Manual);
  const [newAccount, updateNewAccount] = useState<CreateManualAccountArgs>({
    nickname: '',
    accountNumber: '',
    type: AccountType.CreditCard,
  });
  const [csvFiles, setCsvFiles] = useState<CsvFile[] | undefined>(undefined);
  const [fieldMapping, setFieldMapping] = useState<FieldMapping>({
    Merchant: undefined,
    Payee: undefined,
    Amount: undefined,
    Date: undefined,
  });
  const [venmoAccountId, setVenmoAccountId] = useState<string>();

  // Reset the modal state when it closes
  const onClose = useCallback(() => {
    setActiveIndex(0);
    onCloseModal();
  }, [onCloseModal]);

  const [importStatements, { loading: importStatementsLoading }] = useMutation(IMPORT_STATEMENTS);

  const [createManualAccount, { loading: createAccountLoading }] = useMutation(
    CREATE_MANUAL_ACCOUNT,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            accounts(existingAccountRefs = []) {
              const newAccountRef = cache.writeFragment({
                data: data?.createManualAccount,
                fragment: gql`
                fragment NewAccount on Account {
                  id
                }
              `,
              });

              return [...existingAccountRefs, newAccountRef];
            },
          },
        });
      },
    },
  );

  // Hide the modal if the Plaid modal is open
  const modalVisibility = plaidLinkOpen ? 'hidden' : 'inherit';

  const createManualAccountAndStatements = () => {
    if (csvFiles === undefined) {
      return;
    }

    createManualAccount({
      variables: {
        createAccountArgs: newAccount,
      },
    }).then((accountRes) => {
      const createdAccount = accountRes.data?.createManualAccount;
      if (createdAccount === undefined) {
        return;
      }

      importStatementsAction({
        accountId: createdAccount.id,
        accountType: createdAccount.type,
        csvFiles: csvFiles ?? [],
        fieldMapping,
        importStatements,
        onClose,
        colorMode,
      });
    });
  };

  const Pages: Record<number, { title: string; component: JSX.Element }> = {
    [0]: {
      title: 'Choose Account Type',
      component: (
        <ChooseAccountType setActiveIndex={setActiveIndex} setAccountType={setAccountType} />
      ),
    },
    [1]: {
      title: (() => {
        switch (accountType) {
          case CreateAccountType.Manual: {
            return 'Setup Account';
          }
          case CreateAccountType.Automatic: {
            return 'Setup Connection';
          }
          case CreateAccountType.Asset: {
            return 'Setup Asset';
          }
          case CreateAccountType.Email: {
            return 'Setup Venmo';
          }
        }
      })(),
      component: (() => {
        switch (accountType) {
          case CreateAccountType.Manual:
            return (
              <CreateEditAccountForm
                setActiveIndex={setActiveIndex}
                account={newAccount}
                updateAccount={updateNewAccount}
              />
            );
          case CreateAccountType.Automatic:
            return <CreateAutomaticAccount setPlaidLinkOpen={setPlaidLinkOpen} onClose={onClose} />;
          case CreateAccountType.Asset:
            return <SetupAssetAccount onClose={onClose} />;
          case CreateAccountType.Email:
            return (
              <SetupEmailAccount
                next={(accountId) => {
                  setVenmoAccountId(accountId);
                  setActiveIndex(2);
                }}
              />
            );
        }
      })(),
    },
    [2]: {
      title: (() => {
        switch (accountType) {
          case CreateAccountType.Manual: {
            return 'Import CSV Statements';
          }
          case CreateAccountType.Email: {
            return 'Setup Email Forwarding Rule';
          }
          default: {
            return '';
          }
        }
      })(),
      component: (() => {
        switch (accountType) {
          case CreateAccountType.Manual: {
            return (
              <CsvUploadForm
                csvFiles={csvFiles}
                setCsvFiles={setCsvFiles}
                fieldMapping={fieldMapping}
                setFieldMapping={setFieldMapping}
                onSubmit={createManualAccountAndStatements}
                submitting={createAccountLoading || importStatementsLoading}
              />
            );
          }
          case CreateAccountType.Email: {
            return (
              <ForwardInstructionsEmailAccount
                onClose={onClose}
                venmoAccountId={venmoAccountId as string}
              />
            );
          }
          default: {
            return <></>;
          }
        }
      })(),
    },
  };

  const currentPage = Pages[activeIndex];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
      trapFocus={!plaidLinkOpen}
    >
      <ModalOverlay visibility={modalVisibility} />
      <ModalContent visibility={modalVisibility}>
        <ModalHeader display="flex" alignItems={'center'} gap={1}>
          <IconButton
            colorScheme={'gray'}
            variant="ghost"
            hidden={activeIndex === 0}
            aria-label="Back"
            icon={<ChevronLeftIcon w={6} h={6} />}
            onClick={() => setActiveIndex(activeIndex - 1)}
            size={'sm'}
          />
          {currentPage.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="column" justifyContent="center" gap={8}>
          {currentPage.component}
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          <DotProgressIndicator activeIndex={activeIndex} stepCount={3} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
