import { gql } from '@apollo/client';
import { AggregationInterval } from 'src/components/insights/YearPicker';

export const GET_INCOME_VS_EXPENSE = gql`
  query GetIncomeVsExpense(
    $startDate: DateTime!
    $endDate: DateTime!
    $interval: AggregationInterval!
  ) {
    incomeVsExpense(startDate: $startDate, endDate: $endDate, interval: $interval) {
      xLabels
      startDate
      endDate
      aggregations {
        category {
          id
          name
          color
        }
        amounts
      }
    }
  }
`;

export interface GetIncomeVsExpenseInput {
  startDate: Date;
  endDate: Date;
  interval: AggregationInterval;
}

export interface GetIncomeVsExpenseData {
  incomeVsExpense: {
    xLabels: string[];
    startDate: Date;
    endDate: Date;
    aggregations: {
      category: {
        id: string;
        name: string;
        color: string;
      };
      amounts: number[];
    }[];
  };
}
