import { Button, Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNotificationPermission, useNotificationSubscription } from './util';

const PermissionDenied = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={'column'} gap={2}>
      <Heading size="md">{t('notifications.supported.denied.title')}</Heading>
      <Text>{t('notifications.supported.denied.description')}</Text>
    </Flex>
  );
};

const PermissionPrompt = () => {
  const { t } = useTranslation();
  const { subscribe, loading } = useNotificationSubscription();

  return (
    <Flex flex={1} flexDirection="column" gap={6}>
      <Flex flexDirection={'column'} gap={2}>
        <Heading size="md">{t('notifications.supported.prompt.title')}</Heading>
        <Text>{t('notifications.supported.prompt.description')}</Text>
      </Flex>

      <Button onClick={subscribe} isLoading={loading} w={'12rem'} alignSelf="flex-end">
        {t('notifications.subscribeButtonText')}
      </Button>
    </Flex>
  );
};

const PermissionGranted = () => {
  const { t } = useTranslation();
  const { subscription, loading, unsubscribe, subscribe } = useNotificationSubscription();
  const isLoaded = subscription !== undefined;

  const buttonText = subscription
    ? t('notifications.unsubscribeButtonText')
    : t('notifications.subscribeButtonText');

  const titleText = subscription
    ? t('notifications.supported.granted.title')
    : t('notifications.supported.prompt.title');

  const descriptionText = subscription
    ? t('notifications.supported.granted.enabledDescriptionText')
    : t('notifications.supported.prompt.description');

  return (
    <Flex flex={1} flexDirection="column" gap={6}>
      <Skeleton isLoaded={isLoaded}>
        <Flex flexDirection={'column'} gap={2}>
          <Heading size="md">{titleText}</Heading>
          <Text>{descriptionText}</Text>
        </Flex>
      </Skeleton>
      <Skeleton isLoaded={isLoaded} maxW="12rem" alignSelf={'flex-end'}>
        <Button
          onClick={subscription ? unsubscribe : subscribe}
          isLoading={loading}
          variant={subscription ? 'outline' : 'solid'}
          colorScheme={subscription ? 'gray' : undefined}
          w="full"
        >
          {buttonText}
        </Button>
      </Skeleton>
    </Flex>
  );
};

const NotificationBodyContent = () => {
  const permission = useNotificationPermission();

  if (permission === undefined) {
    return null;
  }

  switch (permission) {
    case 'prompt': {
      return <PermissionPrompt />;
    }
    case 'granted': {
      return <PermissionGranted />;
    }
    case 'denied': {
      return <PermissionDenied />;
    }
  }
};

export const NotificationsSupported = () => {
  return (
    <Flex flexDir={'column'} gap={4}>
      <NotificationBodyContent />
    </Flex>
  );
};
