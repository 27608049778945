import { mode, transparentize, getColor } from '@chakra-ui/theme-tools';
import { keyframes } from '@chakra-ui/system';
import { ComponentStyleConfig, extendTheme, Theme, withDefaultColorScheme } from '@chakra-ui/react';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';

const config: Theme['config'] = {
  /**
   * NOTE: Work around to avoid flashing "white" when dark mode is set. It seems like when `initialColorMode` is set to 'system', there is a brief delay before the system value is loaded during which it defaults to light mode.
   * @see https://stackoverflow.com/a/72425684/5141321
   */
  initialColorMode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  useSystemColorMode: true,
};

const styles: Theme['styles'] = {
  global: () => ({
    body: {
      // NOTE: Update index.css and theme-color in index.html as well if you change these
      bg: 'mainBg',
    },
  }),
};

const components: Record<string, ComponentStyleConfig> = {
  Alert: {
    baseStyle: () => ({
      container: {
        borderRadius: 'lg',
      },
    }),
  },
  Container: {
    baseStyle: () => ({
      bg: 'mainBg',
      boxShadow: 'base',
    }),
    variants: {
      card: () => ({
        bg: 'containerBg',
        borderRadius: 'lg',
        boxShadow: 'base',
      }),
    },
  },
  Link: {
    baseStyle: (props) => {
      const { colorScheme: c, theme } = props;

      if (c === undefined) {
        return {};
      }

      const darkColor = transparentize(`${c}.200`, 0.8)(theme);
      const lightColor = `${c}.500`;
      const color = mode(lightColor, darkColor)(props);

      return {
        color,
      };
    },
  },
  Text: {
    baseStyle: () => ({
      color: 'textColor',
      fontSize: 'sm',
    }),
  },
  Button: {
    baseStyle: () => ({
      color: 'textColor',
    }),
    variants: {
      ghost: () => ({
        color: 'textColor',
      }),
      outline: () => ({
        bg: 'containerBg',
      }),
    },
  },
  Heading: {
    baseStyle: () => ({
      color: 'textColor',
    }),
  },
  Input: {
    variants: {
      filled: () => ({
        field: {
          bg: 'containerBg',
          borderColor: 'borderColor',
          borderWidth: 1,
        },
      }),
    },
  },
  Modal: {
    defaultProps: {
      size: {
        base: 'mobile-full',
        md: 'lg',
      },
    },
    baseStyle: {
      dialog: {
        bg: 'containerBg',
      },
    },
    sizes: {
      'mobile-full': {
        dialog: {
          width: '100vw',
          height: '96vh',
          borderTopRadius: 'xl',
          mb: 0,
          mt: '4vh',
          overflow: 'hidden',
        },
      },
    },
  },
  Form: {
    baseStyle: () => ({
      helperText: {
        color: 'subtleText',
      },
    }),
  },
  Table: {
    variants: {
      default: ({ colorScheme, colorMode }) => {
        const c = colorScheme ?? 'gray';
        const px = { base: '0.5rem', md: '1rem' };

        return {
          th: {
            bg: 'containerBg',
            py: 1,
            px,
          },
          td: {
            py: 1,
            px,
          },
          tr: {
            borderBottom: '1px',
            borderColor: mode(`${c}.100`, `${c}.700`)({ colorMode }),
            '@media(hover: hover)': {
              _hover: {
                bg: 'hoverBg',
              },
            },
          },
        };
      },
    },
  },
  Select: {
    variants: {
      filled: () => {
        return {
          field: {
            bg: 'containerBg',
          },
        };
      },
      colored: ({ colorScheme, theme, colorMode }) => {
        const c = colorScheme ?? 'gray';
        const fc = mode('blue.500', 'blue.300')({ colorMode });

        return {
          field: {
            border: '2px solid',
            borderColor: 'transparent',
            bg: transparentize(`${c}.400`, 0.3)(theme),
            color: mode(`${c}.800`, `${c}.200`)({ colorMode }),
            isTruncated: true,
            fontWeight: 'semibold',
            cursor: 'pointer',
            _hover: { bg: transparentize(`${c}.400`, 0.3)(theme) },
            _disabled: {
              bg: transparentize(`${c}.400`, 0.2)(theme),
              cursor: 'not-allowed',
              color: mode(`${c}.500`, `${c}.400`)({ colorMode }),
            },
            _focus: {
              borderColor: getColor(theme, fc),
            },
          },
        };
      },
    },
  },
  Skeleton: {
    // Copied from https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/skeleton.ts
    // Only change is the end color for dark mode to avoid going lighter than the container bg
    baseStyle: (props) => {
      const defaultStartColor = mode('gray.200', 'gray.700')(props);
      const defaultEndColor = mode('gray.400', 'gray.600')(props);

      const { startColor = defaultStartColor, endColor = defaultEndColor, speed, theme } = props;

      const start = getColor(theme, startColor);
      const end = getColor(theme, endColor);

      return {
        opacity: 0.7,
        borderRadius: 'lg',
        borderColor: start,
        background: end,
        animation: `${speed ?? 1}s infinite alternate ${fade(start, end)}`,
      };
    },
  },
  FormLabel: {
    baseStyle: () => ({
      color: 'textColor',
    }),
  },
};

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { borderColor: startColor, background: startColor },
    to: { borderColor: endColor, background: endColor },
  });

const defaultColorScheme = withDefaultColorScheme({
  colorScheme: 'blue',
  components: ['Button'],
});

const semanticTokens = {
  colors: {
    textColor: {
      default: 'blackAlpha.900',
      _dark: 'whiteAlpha.800',
    },
    iconColor: {
      default: 'gray.600',
      _dark: 'gray.500',
    },
    coloredIconColor: {
      default: 'blackAlpha.600',
      _dark: 'whiteAlpha.600',
    },
    indicatorDot: {
      default: 'blue.500',
      _dark: 'blue.300',
    },
    subtleText: {
      default: 'gray.500',
      _dark: 'gray.500',
    },
    mainBg: {
      default: 'gray.50',
      _dark: 'gray.900',
    },
    containerBg: {
      default: 'white',
      _dark: 'gray.800',
    },
    hoverBg: {
      default: 'blackAlpha.50',
      _dark: 'whiteAlpha.50',
    },
    selectedTableRow: {
      default: 'blue.100',
      _dark: 'blue.900',
    },
    activeTableRow: {
      default: 'white',
      _dark: 'gray.800',
    },
    inactiveTableRow: {
      default: 'gray.50',
      _dark: 'gray.900',
    },
    borderColor: {
      default: 'gray.200',
      _dark: 'gray.700',
    },
    warningColor: {
      default: 'yellow.400',
      _dark: 'yellow.200',
    },
    errorColor: {
      default: 'red.400',
      _dark: 'red.300',
    },
    subtleBg: {
      default: 'gray.300',
      _dark: 'gray.500',
    },
  },
};

const fonts = {
  heading: `'Open Sans', sans-serif`,
  body: `'Open Sans', sans-serif`,
};

const shadows = {
  palette: '0 2px 0.75rem rgba(0, 0, 0, 0.15)',
};

const theme = extendTheme(
  { config, styles, components, semanticTokens, fonts, shadows },
  defaultColorScheme,
);

export { theme };
