import { Button, Flex, Heading, Skeleton, Stack, Tag, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GetStripeProductsData } from 'src/graphql/GetStripeProducts';
import { Prices, StripePrice } from './Price';

interface ProductProps {
  product: GetStripeProductsData['stripeProducts'][0];
  loading: boolean;
  eligibleForTrial: boolean;
}

export const Product = ({ product, loading, eligibleForTrial }: ProductProps) => {
  const { t } = useTranslation();
  const [priceId, selectPriceId] = useState<StripePrice['id']>(product.stripePrices[0].id);

  return (
    <Flex
      p={{ base: 4, md: 8 }}
      borderWidth={1}
      borderRadius="lg"
      gap={6}
      flexDir="column"
      flex={1}
      bg={'containerBg'}
    >
      <Stack spacing={2}>
        <Skeleton isLoaded={!loading} w="fit-content">
          <Heading size={'lg'}>{product.name}</Heading>
        </Skeleton>
        <Skeleton isLoaded={!loading} w="fit-content">
          <Text fontSize={'lg'} noOfLines={2}>
            {product.description}
          </Text>
        </Skeleton>
        <Skeleton isLoaded={!loading} hidden={!eligibleForTrial}>
          <Tag size="lg" colorScheme={'green'} variant="solid" w="100%" fontWeight={'semibold'}>
            {t('billing.freeTrialMessage')}
          </Tag>
        </Skeleton>
      </Stack>
      <Prices
        prices={product.stripePrices}
        loading={loading}
        selectedPriceId={priceId}
        selectPriceId={selectPriceId}
      />
      <Skeleton isLoaded={!loading}>
        <Flex alignItems={'center'} flexDirection="column" gap={2}>
          <Button
            as="a"
            href={`${
              import.meta.env.VITE_API_SERVER_BASE_URL
            }/stripe/checkout?stripePriceId=${priceId}`}
            size="lg"
            w="33ch"
          >
            {t('billing.products.subscribeButtonText')}
          </Button>
          <Trans
            i18nKey="billing.products.operatedBy"
            components={{ bold: <strong />, text: <Text /> }}
          />
        </Flex>
      </Skeleton>
    </Flex>
  );
};
