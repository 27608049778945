import { useReactiveVar } from '@apollo/client';
import { Select } from '@chakra-ui/react';
import { Dayjs } from 'dayjs';

import { graphInterval } from 'src/cache';
import { utc, min } from 'src/util/dayjs';

export enum GraphInterval {
  MONTH_TO_DATE = 'month-to-date',
  LAST_MONTH = 'last-month',
  YEAR_TO_DATE = 'year-to-date',
  LAST_YEAR = 'last-year',
  LAST_THREE_MONTHS = 'last-three-months',
}

// TODO: Move this to common and share with backend enum definition
export enum AggregationInterval {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

type ChartParams = {
  startDate: number;
  endDate: number;
  aggregationInterval: AggregationInterval;
};

function selectionToStartDate(value: GraphInterval): [startDate: number, endDate: number] {
  switch (value) {
    case GraphInterval.MONTH_TO_DATE: {
      const start = utc().startOf('month');
      const end = min(start.clone().endOf('month'), utc()) as Dayjs;
      return [start.unix(), end.unix()];
    }
    case GraphInterval.LAST_MONTH: {
      const start = utc().subtract(1, 'month').startOf('month');
      const end = start.clone().endOf('month');
      return [start.unix(), end.unix()];
    }
    case GraphInterval.YEAR_TO_DATE: {
      const start = utc().startOf('year');
      const end = min(start.clone().endOf('year'), utc()) as Dayjs;
      return [start.unix(), end.unix()];
    }
    case GraphInterval.LAST_YEAR: {
      const start = utc().subtract(1, 'year').startOf('year');
      const end = start.clone().endOf('year');
      return [start.unix(), end.unix()];
    }
    case GraphInterval.LAST_THREE_MONTHS: {
      const start = utc().subtract(2, 'month').startOf('month');
      const end = utc().endOf('month');
      return [start.unix(), end.unix()];
    }
  }
}

export const GRAPH_INTERVAL_TO_AGGREGATION_INTERVAL: Record<GraphInterval, AggregationInterval> = {
  [GraphInterval.MONTH_TO_DATE]: AggregationInterval.WEEKLY,
  [GraphInterval.LAST_MONTH]: AggregationInterval.WEEKLY,
  [GraphInterval.YEAR_TO_DATE]: AggregationInterval.MONTHLY,
  [GraphInterval.LAST_YEAR]: AggregationInterval.MONTHLY,
  [GraphInterval.LAST_THREE_MONTHS]: AggregationInterval.MONTHLY,
};

export const intervalToChartParams = (interval: GraphInterval): ChartParams => {
  const [startDate, endDate] = selectionToStartDate(interval);
  return {
    startDate,
    endDate,
    aggregationInterval: GRAPH_INTERVAL_TO_AGGREGATION_INTERVAL[interval],
  };
};

function onChange(selectedInterval: GraphInterval) {
  graphInterval(selectedInterval);
}

function YearPicker() {
  const graphIntervalValue = useReactiveVar(graphInterval);

  return (
    <Select
      variant={'filled'}
      flex={1}
      size={{ base: 'sm', md: 'md' }}
      onChange={(e) => onChange(e.target.value as GraphInterval)}
      value={graphIntervalValue}
      boxShadow="base"
      maxW={'fit-content'}
    >
      <option value={GraphInterval.MONTH_TO_DATE}>Month to Date</option>
      <option value={GraphInterval.LAST_MONTH}>Last Month</option>
      <option value={GraphInterval.YEAR_TO_DATE}>Year to Date</option>
      <option value={GraphInterval.LAST_YEAR}>Last Year</option>
      <option value={GraphInterval.LAST_THREE_MONTHS}>Last 3 Months</option>
    </Select>
  );
}

export { YearPicker };
