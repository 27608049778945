import { Circle, HStack, useColorModeValue } from '@chakra-ui/react';

export const DotProgressIndicator = ({
  activeIndex,
  stepCount,
}: {
  activeIndex: number;
  stepCount: number;
}) => {
  const activeColor = useColorModeValue('gray.500', 'gray.500');
  const inactiveColor = useColorModeValue('gray.300', 'gray.700');

  return (
    <HStack justify={'center'}>
      {[...Array(stepCount)].map((_elm, i) => (
        <Circle key={i} size={'2'} bg={i === activeIndex ? activeColor : inactiveColor} />
      ))}
    </HStack>
  );
};
