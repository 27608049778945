import { gql } from '@apollo/client';

export const GET_ACTIVE_SUBSCRIPTION = gql`
  query GetCurrentSubscription {
    activeSubscription {
      id
      status
      currentPeriodEnd
      cancelAt
      price {
        id
        amount
        currency
        recurrenceInterval
      }
    }
  }
`;

export interface GetActiveSubscriptionData {
  activeSubscription: {
    id: string;
    status:
      | 'TRIALING'
      | 'ACTIVE'
      | 'INCOMPLETE'
      | 'INCOMPLETE_EXPIRED'
      | 'PAST_DUE'
      | 'CANCELED'
      | 'UNPAID';
    currentPeriodEnd: Date;
    cancelAt: Date | null;
    price: {
      id: string;
      amount: number;
      currency: string;
      recurrenceInterval: 'MONTHLY' | 'YEARLY';
    };
  } | null;
}

export const GET_ACTIVE_SUBSCRIPTION_PLACEHOLDER: GetActiveSubscriptionData = {
  activeSubscription: {
    id: 'sub-123',
    status: 'ACTIVE',
    currentPeriodEnd: new Date(),
    cancelAt: new Date(),
    price: {
      id: 'price-456',
      amount: NaN,
      currency: 'usd',
      recurrenceInterval: 'MONTHLY',
    },
  },
};
