import { useMutation } from '@apollo/client';
import { Flex, Button, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { RiBankFill } from 'react-icons/ri';
import {
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import {
  CreatePlaidLinkTokenData,
  CreatePlaidLinkTokenInput,
  CREATE_PLAID_LINK_TOKEN,
} from 'src/graphql/CreatePlaidLinkToken';
import {
  ResetItemExpiresAtData,
  ResetItemExpiresAtInput,
  RESET_ITEM_EXPIRES_AT,
} from 'src/graphql/ResetItemExpiresAt';

interface ReauthorizeModalProps {
  onClose: () => void;
  itemId: string;
  setPlaidLinkOpen: Dispatch<SetStateAction<boolean>>;
}

type PlaidLinkOnSuccessParams = Parameters<PlaidLinkOnSuccess>;

export const ReconnectPlaidButton = ({
  onClose,
  itemId,
  setPlaidLinkOpen,
}: ReauthorizeModalProps) => {
  const [createLinkToken] = useMutation<CreatePlaidLinkTokenData, CreatePlaidLinkTokenInput>(
    CREATE_PLAID_LINK_TOKEN,
  );
  const [resetExpiresAt] = useMutation<ResetItemExpiresAtData, ResetItemExpiresAtInput>(
    RESET_ITEM_EXPIRES_AT,
    {
      optimisticResponse: {
        resetItemExpiresAt: {
          __typename: 'Item',
          id: itemId,
          expiresAt: null,
        },
      },
    },
  );

  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [plaidError, setPlaidError] = useState<boolean>(false);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (_publicToken: PlaidLinkOnSuccessParams[0], _metadata: PlaidLinkOnSuccessParams[1]) => {
      resetExpiresAt({ variables: { itemId } });
      setPlaidLinkOpen(false);
      onClose();
    },
    [onClose],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (_error: Parameters<PlaidLinkOnExit>[0]) => {
      setPlaidLinkOpen(false);
    },
    [onClose],
  );

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { open: openPlaidLink, ready } = usePlaidLink(config);

  useEffect(() => {
    createLinkToken({ variables: { itemId } })
      .then(({ data }) => {
        setLinkToken(data?.createPlaidLinkToken.linkToken ?? null);
      })
      .catch((_err) => {
        setPlaidError(true);
      });
  }, [createLinkToken]);

  return (
    <Flex flexDir={'column'}>
      <Button
        onClick={() => {
          openPlaidLink();
          setPlaidLinkOpen(true);
        }}
        leftIcon={<RiBankFill size={20} />}
        isLoading={!ready}
        isDisabled={!ready || plaidError}
        aria-label="Update Connection"
      >
        Update Connection
      </Button>
      {plaidError && (
        <Text color="red" fontSize="sm">
          Failed to establish a connection
        </Text>
      )}
    </Flex>
  );
};
