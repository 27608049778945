import {
  Flex,
  Input,
  Select,
  IconButton,
  FormControl,
  FormLabel,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import { capitalize, CATEGORY_COLORS } from '@flume-finance/common';
import { EditCategoryData, EditCategoryInput, EDIT_CATEGORY } from 'src/graphql/EditCategory';
import { Reference, useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  DeleteCategoryData,
  DeleteCategoryInput,
  DELETE_CATEGORY,
} from 'src/graphql/DeleteCategory';
import { DeleteConfirmationAlert } from 'src/components/shared/DeleteConfirmationAlert';
import { GetCategoryGroupsData } from 'src/graphql/GetCategoryGroups';
import { SaveState, saveState } from 'src/cache';
import { DEFAULT_CATEGORY_NAME } from './CategoryGroupEdit';

interface EditCategoryProps {
  category: GetCategoryGroupsData['categoryGroups'][0]['categories'][0];
  categoryGroup: GetCategoryGroupsData['categoryGroups'][0];
  isLoaded: boolean;
}

export function CategoryRowEdit({ category, isLoaded, categoryGroup }: EditCategoryProps) {
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const [categoryName, setCategoryName] = useState<string>(category.name);
  const [editCategory] = useMutation<EditCategoryData, EditCategoryInput>(EDIT_CATEGORY, {
    onCompleted: (_data) => {
      saveState(SaveState.SAVED);
    },
    onError: (_err) => {
      saveState(SaveState.ERRORED);
    },
  });

  const [deleteCategory] = useMutation<DeleteCategoryData, DeleteCategoryInput>(DELETE_CATEGORY, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(categoryGroup),
        fields: {
          categories(existingRefs: readonly Reference[], { readField }) {
            return existingRefs.filter(
              (categoryRef) => readField('id', categoryRef) !== data?.deleteCategory.id,
            );
          },
        },
      });
    },
    optimisticResponse: {
      deleteCategory: {
        id: category.id,
      },
    },
  });

  const editCategoryWithResponse = ({ color, name }: { color: string; name: string }) => {
    saveState(SaveState.SAVING);
    editCategory({
      variables: { id: category.id, color, name },
      optimisticResponse: {
        __typename: 'Mutation',
        editCategory: {
          id: category.id,
          name,
          color,
          __typename: 'Category',
        },
      },
    });
  };

  return (
    <Flex gap={{ base: 1, md: 2 }} pl={2} my={1}>
      <FormControl id="category" flex={2}>
        <Skeleton isLoaded={isLoaded} width={'min-content'} fadeDuration={0} mb={-1}>
          <FormLabel fontSize="xs" color="subtleText" textTransform={'uppercase'} fontWeight="bold">
            Category
          </FormLabel>
        </Skeleton>
        <Skeleton isLoaded={isLoaded} fadeDuration={0}>
          <Input
            value={categoryName}
            variant="outline"
            size="sm"
            borderRadius={6}
            onBlur={() => {
              if (categoryName !== category.name) {
                editCategoryWithResponse({ color: category.color, name: categoryName });
              }
            }}
            onClick={(event) => {
              if (event.currentTarget.value === DEFAULT_CATEGORY_NAME) {
                event.currentTarget.select();
              }
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.currentTarget.blur();
              }
            }}
            onChange={(event) => setCategoryName(event.target.value)}
          />
        </Skeleton>
      </FormControl>
      <FormControl id="category-color" flex={1}>
        <Skeleton isLoaded={isLoaded} width={'min-content'} fadeDuration={0} mb={-1}>
          <FormLabel fontSize="xs" color="subtleText" textTransform={'uppercase'} fontWeight="bold">
            Color
          </FormLabel>
        </Skeleton>
        <Skeleton isLoaded={isLoaded} fadeDuration={0}>
          <Select
            variant="colored"
            colorScheme={category.color}
            size="sm"
            borderRadius={6}
            value={category.color}
            onChange={(event) => {
              const newColor = event.target.value;
              editCategoryWithResponse({ color: newColor, name: category.name });
            }}
          >
            {CATEGORY_COLORS.map((color) => (
              <option key={color} value={color}>
                {capitalize(color)}
              </option>
            ))}
          </Select>
        </Skeleton>
      </FormControl>
      <FormControl id="category-delete" flex={0} alignSelf={'flex-end'}>
        <Skeleton isLoaded={isLoaded} width={'min-content'} height="min-content" fadeDuration={0}>
          <IconButton
            colorScheme={'gray'}
            tabIndex={0}
            borderRadius={8}
            type="button"
            size="sm"
            icon={<DeleteIcon />}
            variant="ghost"
            aria-label="Delete Category"
            onClick={() => {
              onAlertOpen();
            }}
          />
        </Skeleton>
      </FormControl>
      <DeleteConfirmationAlert
        isOpen={isAlertOpen}
        resource={`"${category.name}" Category`}
        onClose={onAlertClose}
        onConfirm={
          // TODO: [UX] Figure out how to move existing transactions to a new category
          () => {
            saveState(SaveState.SAVING);
            deleteCategory({ variables: { categoryId: category.id } }).then(() =>
              saveState(SaveState.SAVED),
            );
          }
        }
      />
    </Flex>
  );
}
