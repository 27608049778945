import {
  Alert,
  AlertIcon,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PaperPlaneIcon from 'src/assets/icons/icons8-paper-plane.svg?react';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';
import { LOGOUT, LogoutPayload } from 'src/graphql/Logout';
import { VERIFY_EMAIL, VerifyEmailData, VerifyEmailInput } from 'src/graphql/VerifyEmail';
import { handleLogout } from 'src/util/authentication';

export function VerifyEmailApp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verificationCode, setVerificationCode] = useState<string>(searchParams.get('code') ?? '');
  const [error, setError] = useState<boolean>(false);

  const {
    data: currentUserData,
    refetch,
    loading: currentUserLoading,
  } = useQuery(GET_CURRENT_USER, {
    onCompleted: getCurrentUserCallback,
  });

  const [verifyEmail, { loading }] = useMutation<VerifyEmailData, VerifyEmailInput>(VERIFY_EMAIL, {
    onCompleted: (data) => {
      if (data.verifyEmail.success) {
        refetch();
      } else {
        setError(true);
      }
    },
  });

  const [logoutUser] = useMutation<LogoutPayload>(LOGOUT, {
    onCompleted: (data) => {
      if (data.logout.success) {
        handleLogout({ path: '/signup', navigate });
      }
    },
  });

  const onClick = () => {
    if (verificationCode === null) {
      return;
    }

    verifyEmail({
      variables: {
        code: verificationCode,
      },
    });
  };

  return (
    <Flex
      flexGrow={1}
      align="center"
      justify="center"
      height="100%"
      flexDir={'column'}
      p={{ base: 2, sm: 0 }}
    >
      <Container
        variant={'card'}
        boxShadow="lg"
        padding={{ base: 4, sm: 8 }}
        maxW={'25rem'}
        mb={2}
        display="flex"
        flexDir={'column'}
        alignItems="center"
        gap={6}
      >
        <Alert status="info" fontSize="sm" size="sm">
          <AlertIcon />
          {t('verifyEmail.infoAlertDescription')}
        </Alert>
        <PaperPlaneIcon width={100} height={100} />
        <Flex flexDir={'column'} alignItems="center" gap={2}>
          <Heading size="lg">{t('verifyEmail.title')}</Heading>
          <Trans
            i18nKey="verifyEmail.description"
            values={{ email: currentUserData?.currentUser.email }}
            components={{ bold: <strong />, text: <Text align="center" /> }}
          />
        </Flex>
        <FormControl isInvalid={error}>
          <Flex flexDir={'column'} gap={2}>
            <Input
              variant={'outline'}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder={t('verifyEmail.codeInputPlaceholder') ?? undefined}
            />
            <Button size="md" onClick={onClick} isLoading={loading || currentUserLoading}>
              {t('verifyEmail.verifyButtonText')}
            </Button>
            <FormErrorMessage>{t('verifyEmail.errorInvalidCodeText')}</FormErrorMessage>
          </Flex>
        </FormControl>
      </Container>
      <Link
        as={RouterLink}
        to="/signup"
        colorScheme={'blue'}
        fontWeight="semibold"
        fontSize={'sm'}
        onClick={() => logoutUser()}
      >
        {t('verifyEmail.wrongEmailLinkText')}
      </Link>
    </Flex>
  );
}
