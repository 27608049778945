import { gql } from 'src/graphql/__generated__/gql';
import { AssetType, GetAssetQuery } from 'src/graphql/__generated__/graphql';

export const GET_ASSET = gql(/* GraphQL */ `
  query GetAsset($id: ID!) {
    asset(id: $id) {
      id
      name
      type
      balance
      currencyCode
      assetValues {
        id
        date
        value
      }
    }
  }
`);

export const GET_ASSET_PLACEHOLDER: GetAssetQuery = {
  asset: {
    id: crypto.randomUUID(),
    name: 'Placeholder',
    type: AssetType.Investment,
    balance: 1000n,
    currencyCode: 'USD',
    assetValues: [
      {
        id: crypto.randomUUID(),
        date: new Date(),
        value: 1000n,
      },
    ],
  },
};
