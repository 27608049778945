import { useQuery } from '@apollo/client';
import { Select, Skeleton, ThemingProps } from '@chakra-ui/react';
import { capitalize } from '@flume-finance/common';
import { useRef } from 'react';

import {
  GetCategoryGroupsData,
  GET_CATEGORY_GROUPS,
  GET_CATEGORY_GROUPS_PLACEHOLDER,
} from 'src/graphql/GetCategoryGroups';
import { FilterComponentProps } from 'src/@types/react-table';
import { useTranslation } from 'react-i18next';

const ALL = 'all';
const UNCATEGORIZED = 'uncategorized';
const TRANSFER = 'transfer';

interface CategorySelectProps extends FilterComponentProps<string> {
  size?: ThemingProps<'Select'>['size'];
  disabled?: boolean;
  filterMode?: boolean;
}

function CategorySelect({
  value,
  onChange,
  filterMode = false,
  size = 'sm',
  disabled = false,
}: CategorySelectProps) {
  const { t } = useTranslation();
  const selectRef = useRef<HTMLSelectElement>(null);

  const { loading, data } = useQuery<GetCategoryGroupsData>(GET_CATEGORY_GROUPS);

  const isLoaded = !(loading || data === undefined);
  const categoryGroups =
    isLoaded && data ? data?.categoryGroups : GET_CATEGORY_GROUPS_PLACEHOLDER.categoryGroups;

  const category = categoryGroups.flatMap((cg) => cg.categories).find((c) => c.id === value);
  const color = category?.color ?? 'gray';

  let selectedValue = category?.id ?? UNCATEGORIZED;
  if (disabled) {
    selectedValue = TRANSFER;
  } else if (value === ALL) {
    selectedValue = ALL;
  } else if (value === undefined) {
    selectedValue = filterMode ? ALL : UNCATEGORIZED;
  }

  return (
    <Skeleton isLoaded={isLoaded} fadeDuration={0}>
      <Select
        variant="colored"
        colorScheme={disabled ? 'gray' : color}
        size={size}
        ref={selectRef}
        borderRadius={6}
        disabled={disabled}
        value={selectedValue}
        /* Prevent clicking the select from trigger a click on the underlying transaction row */
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          const selectedCategoryId = e.target.value as string | undefined;
          onChange(selectedCategoryId);
        }}
      >
        {filterMode && (
          <option value={ALL} label={t('categories.select.allOption')}>
            {t('categories.select.allOption')}
          </option>
        )}
        <option value={UNCATEGORIZED} label={t('categories.select.uncategorizedOption')}>
          {t('categories.select.uncategorizedOption')}
        </option>
        {disabled && (
          <option disabled value={TRANSFER} label={t('categories.select.transferOption')}>
            {t('categories.select.transferOption')}
          </option>
        )}
        {categoryGroups.map(({ id, name, categories }) => (
          <optgroup key={id} label={capitalize(name)}>
            {categories.map((c) => (
              <option key={c.id} label={c.name} value={c.id}>
                {capitalize(c.name)}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
    </Skeleton>
  );
}

export { CategorySelect };
