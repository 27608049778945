import { gql } from '@apollo/client';

export const RESET_ITEM_EXPIRES_AT = gql`
  mutation ResetItemExpiresAt($itemId: String!) {
    resetItemExpiresAt(itemId: $itemId) {
      id
      expiresAt
    }
  }
`;

export interface ResetItemExpiresAtInput {
  itemId: string;
}

export interface ResetItemExpiresAtData {
  resetItemExpiresAt: {
    __typename: 'Item';
    id: string;
    expiresAt: Date | null;
  };
}
