import { AppRoot } from 'src/components/shared/AppRoot';
import { TransactionCategorizeSection } from './sections/TransactionCategorizeSection';
import { AutomationSuggestionReviewSection } from './sections/AutomationSuggestionCategorizeSection';
import { Dispatch, SetStateAction, useState } from 'react';

type Section = 'transactions' | 'automationSuggestions';

const getSection = (section: Section, setSection: Dispatch<SetStateAction<Section>>) => {
  switch (section) {
    case 'transactions':
      return <TransactionCategorizeSection />;
    case 'automationSuggestions':
      return <AutomationSuggestionReviewSection onComplete={() => setSection('transactions')} />;
  }
};

export function CategorizeApp() {
  const [section, setSection] = useState<Section>('automationSuggestions');

  return <AppRoot>{getSection(section, setSection)}</AppRoot>;
}
