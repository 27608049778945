import { useQuery, useReactiveVar } from '@apollo/client';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SubscriptionIndicatorInfo, subscriptionIndicatorInfo } from 'src/cache';
import {
  GET_ACTIVE_SUBSCRIPTION,
  GetActiveSubscriptionData,
} from 'src/graphql/GetActiveSubscription';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';

const TIMEOUT_DURATION = 30_000;
const TERMINAL_ALERT_DURATION = 7_000;

type AlertInfo = {
  title: string;
  description: string;
  status: 'info' | 'success' | 'warning';
};

const getAlertInfo = (info: SubscriptionIndicatorInfo | undefined): AlertInfo | undefined => {
  // TODO: [UX] Move to i18n
  switch (info?.status) {
    case 'success':
      return {
        title: 'Subscription confirmed!',
        description:
          'You can now continue using Flume. Let us know if you have any questions or feedback',
        status: 'success',
      };
    case 'info':
      return {
        title: 'Verifying your subscription...',
        description: 'Please wait a few a few moments while we communicate with Stripe',
        status: 'info',
      };
    case 'timeout':
      return {
        title: 'Something went wrong.',
        description:
          'If you received a confirmation email, please reach out to support. Additionally try refreshing the page in a minute or so',
        status: 'warning',
      };
    default:
      return undefined;
  }
};

export const SubscriptionLoadingIndicator = () => {
  const { refetch: refetchCurrentUser } = useQuery(GET_CURRENT_USER, {
    skip: true,
    onCompleted: getCurrentUserCallback,
  });

  const subscriptionIndicatorInfoValue = useReactiveVar(subscriptionIndicatorInfo);
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const shouldPoll = searchParams.get('status') === 'success';

  const {
    data: activeSubData,
    startPolling,
    stopPolling,
  } = useQuery<GetActiveSubscriptionData>(GET_ACTIVE_SUBSCRIPTION, {
    skip: !shouldPoll,
    onError: (_error) => {
      stopPolling();
    },
  });
  const activeSubscription = activeSubData?.activeSubscription;

  // Display info indicator if shouldPoll
  useEffect(() => {
    if (shouldPoll) {
      subscriptionIndicatorInfo({ status: 'info' });
      startPolling(2500);
    }
  }, [shouldPoll]);

  // Stop polling once the subscription is active
  useEffect(() => {
    if (activeSubscription) {
      subscriptionIndicatorInfo({ status: 'success' });
      pollingTimeout && clearTimeout(pollingTimeout);
      stopPolling();

      // Hide the alert after a few seconds
      setTimeout(() => {
        subscriptionIndicatorInfo(undefined);
      }, TERMINAL_ALERT_DURATION);

      // Refetch the current user which includes subscriptions
      refetchCurrentUser();
    }
  }, [activeSubscription]);

  // Stop polling after 30 seconds and display a warning message that things are taking longer than usual
  useEffect(() => {
    setPollingTimeout(
      setTimeout(() => {
        subscriptionIndicatorInfo({ status: 'timeout' });
        stopPolling();

        // Hide the alert after a few seconds
        setTimeout(() => {
          subscriptionIndicatorInfo(undefined);
        }, TERMINAL_ALERT_DURATION);
      }, TIMEOUT_DURATION),
    );
  }, []);

  const alertInfo = getAlertInfo(subscriptionIndicatorInfoValue);
  if (!alertInfo) {
    return null;
  }

  return (
    <Alert
      width={{ base: 'auto', md: 400 }}
      zIndex={'toast'}
      position="fixed"
      bottom={2}
      right={{ base: 2, md: 0 }}
      left={{ base: 2, md: 0 }}
      marginX="auto"
      boxShadow={{ base: 'xl', xl: 'md' }}
      padding={5}
      borderRadius={8}
      status={alertInfo.status}
      variant="solid"
      flexDirection="column"
      alignItems="flex-start"
      hidden={alertInfo === undefined || !shouldPoll}
    >
      <Flex mb={2} alignItems="center">
        {alertInfo.status === 'info' ? (
          <Spinner size="sm" colorScheme={'blue'} mr={3} />
        ) : (
          <AlertIcon />
        )}
        <AlertTitle as="h2">{alertInfo.title}</AlertTitle>
      </Flex>

      <AlertDescription maxWidth="sm">{alertInfo.description}</AlertDescription>
    </Alert>
  );
};
