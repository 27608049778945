import { Button, ButtonProps, Flex, Kbd, Skeleton } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ExitAnimation, Action } from './CategorizeSection';

interface ActionButtonProps {
  mode: Action;
  loading: boolean;
  setExitAnimation: ActionButtonsProps['setExitAnimation'];
  children: React.ReactNode;
  onClick: () => void;
}

const ActionButton = ({
  mode,
  setExitAnimation,
  children,
  loading,
  onClick,
  ...props
}: ActionButtonProps & ButtonProps) => {
  return (
    <Skeleton isLoaded={!loading} fadeDuration={0}>
      <Button
        {...props}
        // NOTE: Set the exit animation on touch start/ mousedown so that it is propagated to the component for the click
        onMouseDown={() => setExitAnimation(mode === 'undo' ? 'undo' : 'skip')}
        onTouchStart={() => setExitAnimation(mode === 'undo' ? 'undo' : 'skip')}
        onClick={onClick}
        gap={2}
      >
        {children}
      </Button>
    </Skeleton>
  );
};

interface ActionButtonsProps {
  loading: boolean;
  onClick: (mode: Action) => () => void;
  setExitAnimation: Dispatch<SetStateAction<ExitAnimation>>;
  disableUndo: boolean;
}

export const ActionButtons = ({
  loading,
  setExitAnimation,
  disableUndo,
  onClick,
}: ActionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent={'space-between'} w="full">
      <ActionButton
        colorScheme={'gray'}
        variant="ghost"
        mode="undo"
        isDisabled={disableUndo}
        loading={loading}
        onClick={onClick('undo')}
        setExitAnimation={setExitAnimation}
      >
        <Kbd>◂</Kbd>
        {t('categorize.backButtonText')}
      </ActionButton>
      <ActionButton
        colorScheme={'gray'}
        variant="ghost"
        mode="skip"
        loading={loading}
        onClick={onClick('skip')}
        setExitAnimation={setExitAnimation}
      >
        {t('categorize.skipButtonText')}
        <Kbd>▸</Kbd>
      </ActionButton>
    </Flex>
  );
};
