import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  FormControl,
  FormLabel,
  Text,
  Button,
  FormHelperText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Select,
  Checkbox,
  Skeleton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ISetTransactionPayee, SetTransactionPayee } from 'src/graphql/SetTransactionPayee';
import { useMutation, useQuery } from '@apollo/client';
import {
  SET_TRANSACTION_NOTES,
  SetTransactionNotesData,
  SetTransactionNotesInput,
} from 'src/graphql/SetTransactionNotes';
import {
  GetPotentialTransfersData,
  GetPotentialTransfersInput,
  GET_POTENTIAL_TRANSFERS,
} from 'src/graphql/GetPotentialTransfers';
import {
  CreateManualTransferData,
  CreateManualTransferInput,
  CREATE_MANUAL_TRANSFER,
} from 'src/graphql/CreateManualTransfer';
import { TransferTransactionTag } from './TransferTransactionTag';
import {
  SetTransactionReviewedData,
  SetTransactionReviewedInput,
  SET_TRANSACTION_REVIEWED,
} from 'src/graphql/SetTransactionReviewed';
import { useTranslation } from 'react-i18next';
import { SplitPanel } from './VenmoPanel';
import { formatCurrency } from 'src/util';
import {
  SetTransactionHiddenData,
  SetTransactionHiddenInput,
  SET_TRANSACTION_HIDDEN,
} from 'src/graphql/SetTransactionHidden';
import { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';
import { CategoryCell } from 'src/components/transactions/table/CategoryCell';

interface EditTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  transaction: GetTransactionsQuery['transactions']['transactions'][0];
}

const EditPanel = ({
  transaction,
  onClose,
}: {
  transaction: EditTransactionModalProps['transaction'];
  onClose: EditTransactionModalProps['onClose'];
}) => {
  const { t } = useTranslation();
  const [payee, setPayee] = useState(transaction.payee);
  const [notes, setNotes] = useState(transaction.notes ?? '');
  const [reviewed, setReviewed] = useState(transaction.reviewed);
  const [hidden, setHidden] = useState(transaction.hidden);

  useEffect(() => {
    setPayee(transaction.payee);
  }, [transaction.payee]);

  useEffect(() => {
    setNotes(transaction.notes ?? '');
  }, [transaction.notes]);

  useEffect(() => {
    setReviewed(transaction.reviewed);
  }, [transaction.reviewed]);

  const [setTransactionPayee, { loading: payeeLoading }] = useMutation<
    ISetTransactionPayee['output'],
    ISetTransactionPayee['input']
  >(SetTransactionPayee.query, {
    update: SetTransactionPayee.update,
    optimisticResponse: SetTransactionPayee.optimisticResponse({
      affectedTransactionIds: [transaction.id],
    }),
  });

  const [setTransactionNotes, { loading: notesLoading }] = useMutation<
    SetTransactionNotesData,
    SetTransactionNotesInput
  >(SET_TRANSACTION_NOTES, {
    optimisticResponse: {
      setTransactionNotes: {
        id: transaction.id,
        notes,
        __typename: 'Transaction',
      },
    },
  });

  const [setTransactionReviewed, { loading: reviewedLoading }] = useMutation<
    SetTransactionReviewedData,
    SetTransactionReviewedInput
  >(SET_TRANSACTION_REVIEWED, {
    optimisticResponse: {
      setTransactionReviewed: {
        id: transaction.id,
        reviewed: reviewed,
        __typename: 'Transaction',
      },
    },
  });

  const [setTransactionHidden, { loading: hiddenLoading }] = useMutation<
    SetTransactionHiddenData,
    SetTransactionHiddenInput
  >(SET_TRANSACTION_HIDDEN, {
    optimisticResponse: {
      setTransactionHidden: {
        id: transaction.id,
        hidden: hidden,
        __typename: 'Transaction',
      },
    },
  });

  const update = () => {
    if (payee !== transaction.payee) {
      setTransactionPayee({
        variables: { transactionId: transaction.id, payee },
      });
    }

    if (notes !== transaction.notes) {
      setTransactionNotes({
        variables: { transactionId: transaction.id, notes },
      });
    }

    if (reviewed !== transaction.reviewed) {
      setTransactionReviewed({
        variables: { transactionId: transaction.id, reviewed: reviewed },
      });
    }

    if (hidden !== transaction.hidden) {
      setTransactionHidden({
        variables: { transactionId: transaction.id, hidden: hidden },
      });
    }

    onClose();
  };

  const isValid = payee.length > 0;

  return (
    <Flex direction="column" alignItems="center" gap={4}>
      <FormControl>
        <FormLabel htmlFor="merchant">
          {t('transactions.editModal.editTab.merchantLabel')}
        </FormLabel>
        <Input
          id="merchant"
          type="text"
          required
          enterKeyHint="next"
          value={payee}
          onChange={(e) => setPayee(e.target.value)}
          isInvalid={payee.length === 0}
        />
        <FormHelperText>{`Payee: "${transaction.rawPayee}"`}</FormHelperText>
      </FormControl>
      <FormControl id="notes">
        <FormLabel>{t('transactions.editModal.editTab.notesLabel')}</FormLabel>
        <Input
          type="text"
          required
          enterKeyHint="go"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </FormControl>
      <FormControl id="category">
        <FormLabel>{t('transactions.editModal.editTab.categoryLabel')}</FormLabel>
        <CategoryCell transaction={transaction} size="md" />
      </FormControl>
      <FormControl id="reviewed">
        <FormLabel>{t('transactions.editModal.editTab.reviewedLabel')}</FormLabel>
        <Checkbox isChecked={reviewed} onChange={(e) => setReviewed(e.target.checked)}>
          {t('transactions.editModal.editTab.isReviewedLabel')}
        </Checkbox>
        <FormHelperText>{t('transactions.editModal.editTab.isReviewedHelperText')}</FormHelperText>
      </FormControl>
      <FormControl id="exclude">
        <FormLabel>{t('transactions.editModal.editTab.hiddenLabel')}</FormLabel>
        <Checkbox isChecked={hidden} onChange={(e) => setHidden(e.target.checked)}>
          {t('transactions.editModal.editTab.isHiddenLabel')}
        </Checkbox>
        <FormHelperText>{t('transactions.editModal.editTab.isHiddenHelperText')}</FormHelperText>
      </FormControl>
      <Flex alignSelf={'flex-end'} gap={2}>
        <Button colorScheme="gray" onClick={onClose} ml={3}>
          {t('common.modal.cancelButtonText')}
        </Button>
        <Button
          onClick={update}
          isLoading={payeeLoading || notesLoading || reviewedLoading || hiddenLoading}
          isDisabled={!isValid}
        >
          {t('common.modal.updateButtonText')}
        </Button>
      </Flex>
    </Flex>
  );
};

const TransferPanel = ({
  transaction,
  onClose,
}: {
  transaction: EditTransactionModalProps['transaction'];
  onClose: EditTransactionModalProps['onClose'];
}) => {
  const { t } = useTranslation();
  const [selectedTransactionId, selectTransactionId] = useState<string>(
    (transaction.amount < 0
      ? transaction.linkedTransfer?.inflowTransaction.id
      : transaction.linkedTransfer?.outflowTransaction.id) ?? '',
  );

  const { data: potentialTransfersData, loading: potentialTransfersLoading } = useQuery<
    GetPotentialTransfersData,
    GetPotentialTransfersInput
  >(GET_POTENTIAL_TRANSFERS, {
    variables: {
      transactionId: transaction.id,
    },
  });

  const [createManualTransfer, { loading: createLoading }] = useMutation<
    CreateManualTransferData,
    CreateManualTransferInput
  >(CREATE_MANUAL_TRANSFER, {
    update(cache, { data }) {
      if (data === undefined || data == null) {
        return;
      }

      // Update the linked transfer in the cache of the inflow and outflow transactions
      [
        data.createManualTransfer.inflowTransaction.id,
        data.createManualTransfer.outflowTransaction.id,
      ].map((tId) => {
        cache.modify({
          id: `Transaction:${tId}`,
          fields: {
            linkedTransfer() {
              return data.createManualTransfer;
            },
          },
        });
      });
    },
    optimisticResponse: {
      createManualTransfer: {
        __typename: 'Transfer',
        id: 'transfer-123',
        inflowTransaction: {
          id: transaction.amount > 0 ? transaction.id : selectedTransactionId,
        },
        outflowTransaction: {
          id: transaction.amount < 0 ? transaction.id : selectedTransactionId,
        },
      },
    },
  });

  return (
    <Flex direction="column" alignItems="center" gap={4}>
      <FormControl>
        <FormLabel htmlFor="transfer">
          {t('transactions.editModal.transferTab.transferTransactionLabel')}
        </FormLabel>
        {transaction.linkedTransfer ? (
          <TransferTransactionTag
            transfer={transaction.linkedTransfer}
            linkedTransactionId={
              transaction.amount > 0
                ? transaction.linkedTransfer.outflowTransaction.id
                : transaction.linkedTransfer.inflowTransaction.id
            }
          />
        ) : (
          <Skeleton isLoaded={!potentialTransfersLoading}>
            <Select
              id="transfer"
              required
              value={selectedTransactionId}
              onChange={(e) => selectTransactionId(e.currentTarget.value)}
            >
              <option value={''}>
                {t('transactions.editModal.transferTab.transferOptionDefaultValue')}
              </option>
              {potentialTransfersData?.potentialTransfers.map((pt) => {
                return (
                  <option key={pt.id} value={pt.id}>{`${new Date(pt.date).toLocaleDateString()} - ${
                    pt.payee
                  }`}</option>
                );
              })}
            </Select>
          </Skeleton>
        )}
      </FormControl>
      <Flex alignSelf={'flex-end'} gap={2}>
        <Button colorScheme="gray" onClick={onClose} ml={3}>
          {t('common.modal.cancelButtonText')}
        </Button>
        <Button
          onClick={() => {
            if (selectedTransactionId === undefined) {
              return;
            }

            createManualTransfer({
              variables:
                transaction.amount < 0
                  ? {
                      inflowTransactionId: selectedTransactionId,
                      outflowTransactionId: transaction.id,
                    }
                  : {
                      inflowTransactionId: transaction.id,
                      outflowTransactionId: selectedTransactionId,
                    },
            });

            onClose();
          }}
          isLoading={createLoading}
          hidden={!!transaction.linkedTransfer}
          isDisabled={selectedTransactionId === undefined || selectedTransactionId === ''}
        >
          {t('common.modal.updateButtonText')}
        </Button>
      </Flex>
    </Flex>
  );
};

export const EditTransactionModal = ({
  isOpen,
  onClose,
  transaction,
}: EditTransactionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} trapFocus={false} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader mr={6}>
          <Flex gap={2}>
            <Text isTruncated flex={1}>
              {transaction.payee}
            </Text>
            <Text color="gray.500">
              {formatCurrency({
                cents: transaction.amount,
                currencyCode: transaction.currencyCode,
              })}
            </Text>
          </Flex>
          <Text fontSize="sm" color="gray.500">
            {transaction.account.nickname}
          </Text>
        </ModalHeader>

        <ModalBody>
          <Tabs variant={'enclosed'}>
            <TabList>
              <Tab>{t('transactions.editModal.tabs.editTabLabel')}</Tab>
              <Tab>{t('transactions.editModal.tabs.transferTabLabel')}</Tab>
              <Tab>{t('transactions.editModal.tabs.splitTabLabel')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditPanel transaction={transaction} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <TransferPanel transaction={transaction} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <SplitPanel transaction={transaction} onClose={onClose} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
