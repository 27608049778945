import { Flex, Heading, Stack, Text, useRadioGroup } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { GetStripeProductsData } from 'src/graphql/GetStripeProducts';
import { formatCurrency } from 'src/util';
import { DiscountTag } from './DiscountTag';
import { RadioCard } from './RadioCard';

export type StripePrice = GetStripeProductsData['stripeProducts'][0]['stripePrices'][0];

interface PriceProps {
  prices: StripePrice[];
  loading: boolean;
  selectedPriceId: StripePrice['id'];
  selectPriceId: Dispatch<SetStateAction<string>>;
}

export const Prices = ({ prices, loading, selectedPriceId, selectPriceId }: PriceProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'subscription',
    value: selectedPriceId,
    onChange: selectPriceId,
  });

  const group = getRootProps();

  return (
    <Flex {...group} flexWrap="wrap" gap={4}>
      {prices.map((price) => {
        const radio = getRadioProps({ value: price.id });
        return (
          <RadioCard key={price.id} loading={loading} {...radio}>
            <Stack alignItems="center">
              <Flex alignItems={'flex-end'} fontWeight="extrabold">
                <Heading size="2xl" fontWeight="inherit" lineHeight="0.9em">
                  {formatCurrency({
                    cents: price.amount,
                    hideCents: true,
                    currencyCode: price.currency,
                  })}
                </Heading>
                <Text fontWeight="inherit" fontSize="2xl">
                  {`/${price.recurrenceInterval === 'MONTHLY' ? 'mo' : 'yr'}`}
                </Text>
              </Flex>
              <DiscountTag prices={prices} price={price} />
            </Stack>
          </RadioCard>
        );
      })}
    </Flex>
  );
};
