import { gql } from 'src/graphql/__generated__';

export const CREATE_MANUAL_ACCOUNT = gql(/* GraphQL */ `
  mutation CreateManualAccount($createAccountArgs: CreateManualAccountArgs!) {
    createManualAccount(createAccountArgs: $createAccountArgs) {
      id
      nickname
      type
    }
  }
`);
