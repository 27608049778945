import { useQuery } from '@apollo/client';
import { useToast, Text, Link, useColorModeValue, Button, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  GetActiveSubscriptionData,
  GET_ACTIVE_SUBSCRIPTION,
} from 'src/graphql/GetActiveSubscription';

const TOAST_ID = 'subscription-expired';

export function SubscriptionExpirationToast() {
  const { data } = useQuery<GetActiveSubscriptionData>(GET_ACTIVE_SUBSCRIPTION);
  const toast = useToast();
  const textColor = useColorModeValue('white', 'black');

  useEffect(() => {
    if (data?.activeSubscription === null && !toast.isActive(TOAST_ID)) {
      toast({
        id: TOAST_ID,
        title: 'Your budget is in readonly mode',
        description: (
          <Flex justify="flex-start" align="center">
            <Text color={textColor}>
              You need an active subscription to pull in new transactions or update existing
              transactions.
            </Text>
            <Button color={textColor} variant="link">
              <Link href="/settings/billing">Go to Billing</Link>
            </Button>
          </Flex>
        ),
        status: 'warning',
        duration: null,
        isClosable: false,
      });
    }
  }, [data]);

  return null;
}
