import { useQuery } from '@apollo/client';
import { Flex, Skeleton, Tag, Text, Tooltip } from '@chakra-ui/react';
import { capitalize, TriggerFieldMatcher } from '@flume-finance/common';
import { useTranslation } from 'react-i18next';
import { GetPendingAutomationSuggestionsData } from 'src/graphql/GetAutomationSuggestions';
import {
  SimulateAutomationData,
  SimulateAutomationInput,
  SIMULATE_AUTOMATION,
} from 'src/graphql/SimulateAutomation';

type AutomationSuggestion = GetPendingAutomationSuggestionsData['pendingAutomationSuggestions'][0];
interface AutomationCardProps<Entity extends AutomationSuggestion> {
  entity: Entity;
  loading: boolean;
}

export const AutomationSuggestionCard = <Entity extends AutomationSuggestion>({
  entity,
  loading,
}: AutomationCardProps<Entity>) => {
  const { t } = useTranslation();
  const { loading: simulateLoading, data } = useQuery<
    SimulateAutomationData,
    SimulateAutomationInput
  >(SIMULATE_AUTOMATION, {
    skip: loading,
    variables: {
      simulateAutomationInput: {
        triggers: [
          {
            field: entity.field,
            matcher: TriggerFieldMatcher.EQUALS,
            value: entity.value,
          },
        ],
        overrideExistingCategories: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const transactionCount = data?.simulateAutomation.count ?? 0;

  return (
    <Skeleton isLoaded={!loading} flex={1} overflow="hidden" w="full">
      <Flex flexDir="column" gap={4} flex={1} justifyContent="space-between" minH={'8.5rem'}>
        <Flex justify="flex-start" gap={2} align="center">
          <Tag size="lg">
            <Text fontFamily="monospace" isTruncated>
              {capitalize(entity.field)}
            </Text>
          </Tag>
          <Tag size="lg">
            <Text fontFamily="monospace" isTruncated>
              {TriggerFieldMatcher.EQUALS}
            </Text>
          </Tag>
          <Tooltip label={entity.value}>
            <Tag size="lg">
              <Text fontFamily="monospace" isTruncated>
                {entity.value}
              </Text>
            </Tag>
          </Tooltip>
        </Flex>
        <Flex gap={2} justifyContent="flex-end">
          <Skeleton isLoaded={!simulateLoading}>
            <Text fontSize={'2xl'} fontWeight="semibold">
              {transactionCount}
            </Text>
            <Text fontSize="xs" color="gray.500" fontWeight="semibold" noOfLines={1}>
              {t('categorize.automationSuggestions.transactionCount', { count: transactionCount })}
            </Text>
          </Skeleton>
        </Flex>
      </Flex>
    </Skeleton>
  );
};
