import { gql } from '@apollo/client';

export const GET_POTENTIAL_TRANSFERS = gql`
  query GetPotentialTransfers($transactionId: String!) {
    potentialTransfers(transactionId: $transactionId) {
      id
      date
      payee
    }
  }
`;

export interface GetPotentialTransfersInput {
  transactionId: string;
}

export interface GetPotentialTransfersData {
  potentialTransfers: {
    id: string;
    date: string;
    payee: string;
  }[];
}
