import { gql, Reference } from '@apollo/client';
import { GraphqlMutation } from 'src/util/graphql';

export interface IDeleteAutomaton {
  input: {
    automationId: string;
  };
  output: {
    deleteAutomation: {
      id: string;
      __typename: 'DeleteAutomationResponse';
    };
  };
}

export const DeleteAutomation: GraphqlMutation<IDeleteAutomaton, { automationId: string }> = {
  query: gql`
    mutation DeleteAutomation($automationId: String!) {
      deleteAutomation(automationId: $automationId) {
        id
      }
    }
  `,
  update: (cache, { data }) => {
    cache.modify({
      fields: {
        automations(existingAutomationRefs: readonly Reference[], { readField }) {
          return existingAutomationRefs.filter(
            (automationRef) => readField('id', automationRef) !== data?.deleteAutomation.id,
          );
        },
      },
    });
  },
  optimisticResponse: ({ automationId }) => ({
    deleteAutomation: {
      id: automationId,
      __typename: 'DeleteAutomationResponse' as const,
    },
  }),
};
