export const capitalize = (str: string) => {
  if (str.length === 0) return '';
  const words = str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .trim()
    .split(/\s+|_/);

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(' ');
};

/** Return a set of elements that appear more than once in the input array */
export const getDuplicateElements = <T>(arr: T[]): Set<T> => {
  const seen = new Set<T>();
  const duplicates = new Set<T>();

  for (const elm of arr) {
    if (seen.has(elm)) {
      duplicates.add(elm);
    }

    seen.add(elm);
  }

  return duplicates;
};

export const shallowEqual = <T,>(a: Record<string, T>, b: Record<string, T>): boolean => {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  return (
    // Same number of keys
    aKeys.length === bKeys.length &&
    // Same keys
    aKeys.every((aKey) => bKeys.includes(aKey)) &&
    // Same values for each key
    aKeys.every((aKey) => a[aKey] === b[aKey])
  );
};
