import { Reference, useMutation } from '@apollo/client';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Skeleton,
} from '@chakra-ui/react';
import { CATEGORY_COLORS } from '@flume-finance/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveState, saveState } from 'src/cache';
import {
  CreateCategoryData,
  CreateCategoryInput,
  CREATE_CATEGORY,
} from 'src/graphql/CreateCategory';
import {
  DeleteCategoryGroupData,
  DeleteCategoryGroupInput,
  DELETE_CATEGORY_GROUP,
} from 'src/graphql/DeleteCategoryGroup';
import {
  EditCategoryGroupData,
  EditCategoryGroupInput,
  EDIT_CATEGORY_GROUP,
} from 'src/graphql/EditCategoryGroup';

import { GetCategoryGroupsData } from 'src/graphql/GetCategoryGroups';
import { CategoryRowEdit } from './CategoryRowEdit';

interface CategoryGroupEditProps {
  categoryGroup: GetCategoryGroupsData['categoryGroups'][0];
  isLoaded: boolean;
}

export const DEFAULT_CATEGORY_NAME = 'New Category';

export function CategoryGroupEdit({ categoryGroup, isLoaded }: CategoryGroupEditProps) {
  const { t } = useTranslation();
  const [categoryGroupName, setCategoryGroupName] = useState<string>(categoryGroup.name);
  const [editCategoryGroup] = useMutation<EditCategoryGroupData, EditCategoryGroupInput>(
    EDIT_CATEGORY_GROUP,
    {
      onCompleted: (_data) => {
        saveState(SaveState.SAVED);
      },
      onError: (_err) => {
        saveState(SaveState.ERRORED);
      },
    },
  );

  const [createCategory, { loading: createLoading }] = useMutation<
    CreateCategoryData,
    CreateCategoryInput
  >(CREATE_CATEGORY, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      // Add the new category to the cache
      cache.modify({
        id: cache.identify(categoryGroup),
        fields: {
          categories(previous, { toReference }) {
            return [...previous, toReference(data.createCategory, true)];
          },
        },
      });
    },
  });

  const [deleteCategoryGroup] = useMutation<DeleteCategoryGroupData, DeleteCategoryGroupInput>(
    DELETE_CATEGORY_GROUP,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            categoryGroups(existingRefs: readonly Reference[], { readField }) {
              return existingRefs.filter(
                (categoryGroupRef) =>
                  readField('id', categoryGroupRef) !== data?.deleteCategoryGroup.id,
              );
            },
          },
        });
      },
      optimisticResponse: {
        deleteCategoryGroup: {
          id: categoryGroup.id,
        },
      },
    },
  );

  return (
    <Flex key={categoryGroup.id} direction="column">
      <Flex alignItems="center" gap={2}>
        <FormControl id="group">
          <Skeleton isLoaded={isLoaded} width={'min-content'} fadeDuration={0} mb={-1}>
            <FormLabel
              fontSize="xs"
              color="subtleText"
              textTransform={'uppercase'}
              fontWeight="bold"
            >
              Group
            </FormLabel>
          </Skeleton>
          <Skeleton isLoaded={isLoaded} fadeDuration={0}>
            <Input
              value={categoryGroupName}
              variant="outline"
              size="sm"
              borderRadius={6}
              onBlur={() => {
                if (categoryGroupName !== categoryGroup.name) {
                  saveState(SaveState.SAVING);
                  editCategoryGroup({
                    variables: { id: categoryGroup.id, name: categoryGroupName },
                  });
                }
              }}
              onClick={(event) => {
                if (event.currentTarget.value === t('categories.defaultCategoryGroupName')) {
                  event.currentTarget.select();
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.currentTarget.blur();
                }
              }}
              onChange={(event) => {
                setCategoryGroupName(event.target.value);
              }}
            />
          </Skeleton>
        </FormControl>
        <FormControl id="category-delete" flex={0} alignSelf={'flex-end'}>
          <Skeleton isLoaded={isLoaded} width={'min-content'} height="min-content" fadeDuration={0}>
            <IconButton
              colorScheme={'gray'}
              tabIndex={0}
              size="sm"
              borderRadius={8}
              type="button"
              icon={<DeleteIcon />}
              variant="ghost"
              aria-label="Delete Category Group"
              hidden={categoryGroup.categories.length !== 0}
              onClick={() => {
                deleteCategoryGroup({ variables: { categoryGroupId: categoryGroup.id } });
              }}
            />
          </Skeleton>
        </FormControl>
      </Flex>
      <Flex flexDirection={'column'} gap={1} mt={2}>
        {categoryGroup.categories.map((category) => (
          <CategoryRowEdit
            key={category.id}
            category={category}
            isLoaded={isLoaded}
            categoryGroup={categoryGroup}
          />
        ))}
      </Flex>
      <Skeleton isLoaded={isLoaded} width={'min-content'} alignSelf="flex-end" fadeDuration={0}>
        <Button
          colorScheme={'gray'}
          type="submit"
          leftIcon={<AddIcon w={3} h={3} />}
          variant="ghost"
          size={'sm'}
          my={1}
          isLoading={createLoading}
          onClick={() => {
            createCategory({
              variables: {
                name: DEFAULT_CATEGORY_NAME,
                color: CATEGORY_COLORS[0],
                groupId: categoryGroup.id,
              },
            });
          }}
        >
          Category
        </Button>
      </Skeleton>
    </Flex>
  );
}
