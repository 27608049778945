import { Resource, use, changeLanguage } from 'i18next';
import { translation as enTranslation } from './locales/en';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources: Resource = {
  en: {
    translation: enTranslation,
  },
} as const;

const DETECTION_OPTIONS = {
  order: ['navigator'],
};

use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en'],
    detection: DETECTION_OPTIONS,
    resources,
  });

changeLanguage();
