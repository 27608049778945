import { Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { DeleteAccountButton } from './DeleteAccountButton';
// import { useTranslation } from 'react-i18next';

export const AccountPage = () => {
  // const { t } = useTranslation();

  return (
    <Flex flexDir={'column'} gap={8}>
      <Flex as="section" gap={2} flexDir="column">
        <Heading size="md">Export transaction data</Heading>
        <Divider />

        <Text>Export all of your transactions including their categories and notes</Text>
        <Button
          colorScheme={'gray'}
          maxW="fit-content"
          as="a"
          href={`${import.meta.env.VITE_API_SERVER_BASE_URL}/transactions/export`}
        >
          Export data
        </Button>
      </Flex>
      <Flex as="section" gap={2} flexDir="column">
        <Heading size={'md'}>Delete account</Heading>
        <Divider />

        <Text>Export all of your transactions including their categories and notes</Text>
        <DeleteAccountButton />
      </Flex>
    </Flex>
  );
};
