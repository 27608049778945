import { MutationHookOptions, TypedDocumentNode } from '@apollo/client';
import { DocumentNode } from 'graphql';

/**
 * Helper to facilitate skeleton UI loading states. If data is undefined then we return placeholder data
 * to populate the skeleton components as well as isInitialLoaded false
 */
export const getQueryData = <T>(
  data: T | undefined,
  placeholder: T,
): { queryData: T; isInitialLoaded: boolean } => {
  return {
    isInitialLoaded: data !== undefined,
    queryData: data ?? placeholder,
  };
};

export interface GraphqlMutation<
  // biome-ignore lint/suspicious/noExplicitAny: This interface should be generic
  T extends { input: Record<string, any>; output: Record<string, any> },
  OptimisticResponseInput = undefined,
> {
  query: DocumentNode | TypedDocumentNode;
  update?: MutationHookOptions<T['output'], T['input']>['update'];
  optimisticResponse: (args: OptimisticResponseInput) => T['output'];
}
