import {
  Box,
  HStack,
  Link,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GetActiveSubscriptionData } from 'src/graphql/GetActiveSubscription';
import { formatCurrency } from 'src/util';

const BILLING_PORTAL_URL = `${import.meta.env.VITE_API_SERVER_BASE_URL}/stripe/billing-portal`;

interface ActiveSubscriptionProps {
  data: GetActiveSubscriptionData['activeSubscription'];
  loading: boolean;
}

interface InfoTileProps {
  title: string;
  children?: React.ReactChild;
  links: {
    text: string;
    href: string;
  }[];
  loading: boolean;
}
const InfoTile = ({ title, links, loading, children }: InfoTileProps) => {
  const bg = useColorModeValue('gray.50', 'gray.700');

  return (
    <Skeleton isLoaded={!loading} flexGrow={1}>
      <Stack
        bg={bg}
        px={6}
        py={4}
        borderRadius={'lg'}
        spacing={4}
        height={'100%'}
        justify={'space-between'}
      >
        <Box>
          <Text fontSize={'sm'}>{title}</Text>
          {children}
        </Box>
        <Stack spacing={0.5}>
          {links.map(({ text, href }) => (
            <Link key={text} href={href} fontSize="xs" fontWeight="bold" colorScheme={'blue'}>
              {text}
            </Link>
          ))}
        </Stack>
      </Stack>
    </Skeleton>
  );
};

const PriceInfoTile = ({
  subscription,
  loading,
}: {
  loading: boolean;
  subscription: NonNullable<GetActiveSubscriptionData['activeSubscription']>;
}) => {
  const { t } = useTranslation();
  const interval =
    subscription.price.recurrenceInterval === 'MONTHLY'
      ? t('billing.tiles.priceInfo.monthly')
      : t('billing.tiles.priceInfo.yearly');
  const switchText =
    subscription.price.recurrenceInterval === 'MONTHLY'
      ? t('billing.tiles.priceInfo.yearly')
      : t('billing.tiles.priceInfo.monthly');

  const content = subscription.cancelAt ? (
    <Text fontSize={'2xl'} fontWeight="bold">
      {t('billing.tiles.priceInfo.canceledText')}
    </Text>
  ) : (
    <HStack>
      <Text fontSize={'2xl'} fontWeight="bold">
        {formatCurrency({
          cents: subscription.price.amount,
          hideCents: false,
          currencyCode: subscription.price.currency,
        })}
      </Text>
      <Text fontSize={'xs'} fontWeight="bold">
        {t('billing.tiles.priceInfo.includesTax')}
      </Text>
    </HStack>
  );

  return (
    <InfoTile
      title={`Current ${interval} bill`}
      links={[
        subscription.cancelAt
          ? {
              text: t('billing.tiles.paymentInfo.renewSubscriptionLinkText'),
              href: BILLING_PORTAL_URL,
            }
          : {
              text: t('billing.tiles.priceInfo.linkText', { interval: switchText }),
              href: BILLING_PORTAL_URL,
            },
      ]}
      loading={loading}
    >
      {content}
    </InfoTile>
  );
};

export const ActiveSubscription = ({ data, loading }: ActiveSubscriptionProps) => {
  const { t } = useTranslation();

  if (data === null) {
    return null;
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
      <PriceInfoTile subscription={data} loading={loading} />
      <InfoTile
        title={
          data.status === 'TRIALING'
            ? t('billing.tiles.recurrenceInfo.trialTitle')
            : t('billing.tiles.recurrenceInfo.regularTitle')
        }
        links={[{ text: t('billing.tiles.recurrenceInfo.linkText'), href: BILLING_PORTAL_URL }]}
        loading={loading}
      >
        <Text fontSize={'2xl'} fontWeight="bold">
          {data.currentPeriodEnd.toLocaleDateString()}
        </Text>
      </InfoTile>
      <InfoTile
        title={t('billing.tiles.paymentInfo.title')}
        links={[
          { text: t('billing.tiles.paymentInfo.updatePaymentLinkText'), href: BILLING_PORTAL_URL },
          data.cancelAt
            ? {
                text: t('billing.tiles.paymentInfo.renewSubscriptionLinkText'),
                href: BILLING_PORTAL_URL,
              }
            : {
                text: t('billing.tiles.paymentInfo.cancelSubscriptionLinkText'),
                href: BILLING_PORTAL_URL,
              },
        ]}
        loading={loading}
      />
    </SimpleGrid>
  );
};
