import { useMutation, useQuery } from '@apollo/client';
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModePreference,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CsvUploadForm, FieldMapping } from 'src/components/csvUpload/CsvUploadForm';
import { CsvFile } from 'src/components/csvUpload/FileSelect';
import { EDIT_ACCOUNT } from 'src/graphql/EditAccount';
import { GET_ACCOUNT } from 'src/graphql/GetAccount';
import { IMPORT_STATEMENTS, importStatementsAction } from 'src/graphql/ImportStatements';
import {
  CreateManualAccountArgs,
  EditAccountMutation,
  EditAccountMutationVariables,
  GetAccountQuery,
  SyncMode,
} from 'src/graphql/__generated__/graphql';
import { CreateEditAccountForm } from './createAccountFlow/CreateEditAccountForm';

export const UploadPanel = ({
  account,
  onClose,
}: {
  account: GetAccountQuery['account'];
  onClose: EditAccountModalProps['onClose'];
}) => {
  const colorMode = useColorModePreference();
  const [csvFiles, setCsvFiles] = useState<CsvFile[] | undefined>(undefined);
  const [fieldMapping, setFieldMapping] = useState<FieldMapping>({
    Merchant: undefined,
    Payee: undefined,
    Amount: undefined,
    Date: undefined,
  });

  const [importStatements, { loading: importStatementsLoading }] = useMutation(IMPORT_STATEMENTS);

  if (account.syncMode === SyncMode.Automatic) {
    return (
      <HStack spacing={4}>
        <WarningTwoIcon w={6} h={6} />
        <Text fontSize="sm">
          The ability to import transactions is currently only available for manual accounts
        </Text>
      </HStack>
    );
  }

  return (
    <CsvUploadForm
      csvFiles={csvFiles}
      setCsvFiles={setCsvFiles}
      fieldMapping={fieldMapping}
      setFieldMapping={setFieldMapping}
      onSubmit={() => {
        importStatementsAction({
          accountId: account.id,
          accountType: account.type,
          csvFiles: csvFiles ?? [],
          fieldMapping,
          importStatements,
          onClose,
          colorMode,
        });
      }}
      submitting={importStatementsLoading}
    />
  );
};

export const EditPanel = ({
  account,
  onClose,
}: {
  account: GetAccountQuery['account'];
  onClose: EditAccountModalProps['onClose'];
}) => {
  const [updatedAccount, updateAccount] = useState<CreateManualAccountArgs>({
    nickname: account.nickname,
    accountNumber: account.accountNumber ?? '',
    type: account.type,
  });

  const [editAccount] = useMutation<EditAccountMutation, EditAccountMutationVariables>(
    EDIT_ACCOUNT,
    {
      optimisticResponse: {
        editAccount: { ...updatedAccount, id: account.id },
      },
    },
  );

  return (
    <CreateEditAccountForm
      account={updatedAccount}
      updateAccount={updateAccount}
      setActiveIndex={() => {
        editAccount({ variables: { editAccountArgs: { ...updatedAccount, id: account.id } } });
        onClose();
      }}
      buttonText="Save"
    />
  );
};

interface EditAccountModalProps {
  isOpen: boolean;
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  accountId: GetAccountQuery['account']['id'] | undefined;
}
export const EditAccountModal = ({ isOpen, onClose, accountId }: EditAccountModalProps) => {
  const { data } = useQuery(GET_ACCOUNT, {
    variables: { id: accountId as string },
    skip: accountId === undefined,
  });

  const account = data?.account;

  if (account === undefined) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{account.nickname}</Text>
        </ModalHeader>
        <ModalBody>
          <Tabs variant={'enclosed'}>
            <TabList>
              <Tab>Edit</Tab>
              <Tab>Import</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditPanel account={account} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <UploadPanel account={account} onClose={onClose} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
