import { MutationTuple } from '@apollo/client';
import { gql } from 'src/graphql/__generated__/gql';
import { cache as gqlCache } from 'src/cache';
import { displayFileToasts } from 'src/components/csvUpload/FileUploadToast';
import { ColorMode, useDisclosure } from '@chakra-ui/react';
import { CsvFile } from 'src/components/csvUpload/FileSelect';
import { FieldMapping } from 'src/components/csvUpload/CsvUploadForm';
import { parseAmount } from 'src/util';
import {
  AccountType,
  GetAccountQuery,
  ImportStatementsMutation,
  ImportStatementsMutationVariables,
  StatementTransaction,
} from 'src/graphql/__generated__/graphql';

export const IMPORT_STATEMENTS = gql(/* GraphQL */ `
  mutation ImportStatements($accountId: String!, $statements: [StatementFile!]!) {
    importStatements(accountId: $accountId, statements: $statements) {
      filename
      status
    }
  }
`);

const importStatementsCallback = ({
  data,
  accountId,
  onClose,
  colorMode,
}: {
  data: ImportStatementsMutation | null | undefined;
  accountId: ImportStatementsMutationVariables['accountId'];
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  colorMode: ColorMode | undefined;
}) => {
  if (data === undefined || data === null) {
    return;
  }

  // Reload account balance, transactions, and net worth after transactions have been created
  gqlCache.evict({ id: `Account:${accountId}`, fieldName: 'formattedBalance' });
  gqlCache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
  gqlCache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
  gqlCache.gc();

  displayFileToasts(data.importStatements, colorMode);
  onClose();
};

interface ImportStatementsActionArgs {
  accountId: GetAccountQuery['account']['id'];
  accountType: GetAccountQuery['account']['type'];
  csvFiles: CsvFile[];
  fieldMapping: FieldMapping;
  importStatements: MutationTuple<ImportStatementsMutation, ImportStatementsMutationVariables>['0'];
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  colorMode?: ColorMode;
}

export const importStatementsAction = ({
  accountId,
  accountType,
  colorMode,
  csvFiles,
  fieldMapping,
  importStatements,
  onClose,
}: ImportStatementsActionArgs) => {
  // NOTE: Flip the amount sign for credit card transactions because those treat debts as positive numbers
  const transactionAmountSign = accountType === AccountType.CreditCard ? -1 : 1;
  const input = {
    accountId,
    statements: csvFiles.map((csvF) => {
      return {
        fileName: csvF.fileName,
        fileHash: csvF.fileHash,
        transactions: csvF.data
          .map((t) => {
            const merchant = fieldMapping.Merchant && t[fieldMapping.Merchant];
            const payee = fieldMapping.Payee && t[fieldMapping.Payee];
            const date = fieldMapping.Date && t[fieldMapping.Date];
            const amount = fieldMapping.Amount && t[fieldMapping.Amount];

            // Skip CSV row is any of the fields are undefined
            if (merchant && payee && date && amount) {
              return {
                merchant,
                payee,
                date: new Date(date),
                amount: BigInt(parseAmount(amount)) * BigInt(transactionAmountSign),
              };
            } else {
              return undefined;
            }
          })
          .filter((t): t is StatementTransaction => t !== undefined),
      };
    }),
  };

  importStatements({
    variables: input,
  }).then((res) => {
    importStatementsCallback({ data: res.data, accountId, onClose, colorMode });
  });
};
