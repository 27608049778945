import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '@flume-finance/ui';
import { currentMonth } from 'src/cache';
import { FRAGMENT_CATEGORY_BUDGETS, FragmentCategoryBudgetsData } from 'src/graphql/GetBudget';
import { GetCategoryGroupsWithSpendData } from 'src/graphql/GetCategoryGroupsWithSpend';
import { GET_CURRENT_USER } from 'src/graphql/GetCurrentUser';
import {
  SET_CATEGORY_BUDGET,
  SetCategoryBudgetData,
  SetCategoryBudgetInput,
} from 'src/graphql/SetCategoryBudget';
import { formatCurrency } from 'src/util';
import { utc } from 'src/util/dayjs';
import { CategoryBudgetModal } from './CategoryBudgetModal';

interface BudgetRowProps {
  category: GetCategoryGroupsWithSpendData['categoryGroups'][0]['categories'][0];
  budgetId: string;
  categoryBudgetId?: string;
  isLoaded: boolean;
  budgeted: number | undefined;
}

interface AmountMessageProps {
  budgeted: number | undefined;
  spent: number;
}

interface BudgetBarProps {
  value: number;
}

function BudgetBar({ value }: BudgetBarProps) {
  const barBgColor = useColorModeValue('gray.200', 'gray.700');
  const dotBgGrayColor = useColorModeValue('gray.400', 'gray.800');

  value = Math.min(100, Math.max(value, 0));
  const linearSpendPercent = Math.round((utc().date() / utc().daysInMonth()) * 100);
  const currentSpendPercent = value;

  const currentMonthValue = useReactiveVar(currentMonth);

  let barColor = 'green';
  if (value > 90) {
    barColor = 'red';
  } else if (value > 70) {
    barColor = 'yellow';
  }

  const dotColor = linearSpendPercent <= currentSpendPercent ? `${barColor}.700` : dotBgGrayColor;

  return (
    <Box flex={1} height={4} bg={barBgColor} borderRadius={10}>
      <Box
        width={`${currentSpendPercent}%`}
        transition="width 0.5s ease"
        height={4}
        bgGradient={`linear(to-r, ${barColor}.300, ${barColor}.400)`}
        borderRadius={10}
      />
      <Tooltip
        label="Target assuming consistent spending throughout the month"
        aria-label="A tooltip"
      >
        <Box
          marginTop={'-1rem'}
          marginLeft={`max(${linearSpendPercent}% - 1.5rem, -0.5rem)`}
          width={'2rem'}
          height={'1rem'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          hidden={utc(currentMonthValue).endOf('month').isBefore(utc())}
        >
          <Box w={'0.75rem'} h={'0.75rem'} bg={dotColor} borderRadius={'50%'} />
        </Box>
      </Tooltip>
    </Box>
  );
}

function AmountMessage({ budgeted, spent }: AmountMessageProps) {
  const { data: currentUserData } = useQuery(GET_CURRENT_USER);
  const currencyCode = currentUserData?.currentUser.currencyCode ?? 'USD';

  const fontSize = 'md';

  if (budgeted) {
    const remaining = budgeted + spent;
    if (remaining < 0) {
      return (
        <Tag bg={transparentize('red.400', 0.6)(theme)} size="lg">
          <Text fontWeight="semibold" fontSize={fontSize}>{`${formatCurrency({
            cents: remaining,
            currencyCode,
          })}`}</Text>
        </Tag>
      );
    } else {
      return (
        <Text fontSize={fontSize}>{`${formatCurrency({
          cents: remaining,
          currencyCode,
        })} remaining`}</Text>
      );
    }
  } else {
    return <Text fontSize={fontSize}>{formatCurrency({ cents: spent, currencyCode })}</Text>;
  }
}

export function BudgetRow({
  category,
  budgetId,
  isLoaded,
  budgeted,
  categoryBudgetId,
}: BudgetRowProps) {
  const spent = category.spend;
  const percentUsed = Math.max(Math.abs(spent / (budgeted ?? 1)) * 100, 0);
  const progressValue = budgeted ? percentUsed : 0;

  const [setCategoryBudget, { loading }] = useMutation<
    SetCategoryBudgetData,
    SetCategoryBudgetInput
  >(SET_CATEGORY_BUDGET, {
    update: (cache, { data }) => {
      const newCategoryBudget = data?.setCategoryBudget;
      if (newCategoryBudget === undefined) {
        return;
      }

      const newBudgetId = newCategoryBudget.budget.id;
      let categoryBudgets =
        cache.readFragment<FragmentCategoryBudgetsData>({
          id: `Budget:${newBudgetId}`,
          fragment: FRAGMENT_CATEGORY_BUDGETS,
          fragmentName: 'CategoryBudgets',
        })?.categoryBudgets ?? [];

      const index = categoryBudgets?.findIndex((cb) => cb.id === newCategoryBudget?.id);
      if (index !== -1) {
        categoryBudgets = [...categoryBudgets];
        categoryBudgets.splice(index, 1, newCategoryBudget);
      } else {
        categoryBudgets = [...categoryBudgets, newCategoryBudget];
      }

      cache.writeFragment({
        id: `Budget:${newBudgetId}`,
        fragment: FRAGMENT_CATEGORY_BUDGETS,
        fragmentName: 'CategoryBudgets',
        data: { categoryBudgets },
      });
    },
  });

  const budgetButtonIcon = budgeted ? <EditIcon /> : <AddIcon />;
  const budgetButtonLabel = budgeted ? 'Edit Budget' : 'Create Budget';
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  return (
    <Flex direction="column" gap={1}>
      <Flex direction="row" justify="space-between" align="center">
        <Skeleton isLoaded={isLoaded}>
          <Heading size="sm">{category.name}</Heading>
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          <Flex align="center" gap={2}>
            <Heading size="md">
              <AmountMessage budgeted={budgeted} spent={spent} />
            </Heading>
            <IconButton
              colorScheme={'gray'}
              size="xs"
              type="button"
              icon={budgetButtonIcon}
              variant="ghost"
              aria-label={budgetButtonLabel}
              onClick={() => onAlertOpen()}
              isLoading={loading}
            />
          </Flex>
        </Skeleton>
      </Flex>
      <Skeleton isLoaded={isLoaded}>
        <BudgetBar value={Math.round(progressValue)} />
      </Skeleton>
      <CategoryBudgetModal
        categoryName={category.name}
        currentBudgeted={budgeted}
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onConfirm={(amount: number) => {
          setCategoryBudget({
            variables: { budgetId: budgetId, categoryId: category.id, amount },
            optimisticResponse: {
              setCategoryBudget: {
                id: categoryBudgetId ?? `optimistic-categoryBudget-${category.id}-${budgetId}`,
                category: {
                  id: category.id,
                  __typename: 'Category',
                },
                budget: {
                  id: budgetId,
                  __typename: 'Budget',
                },
                budgeted: amount,
                __typename: 'CategoryBudget',
              },
            },
          });
        }}
        isEdit={budgeted !== undefined}
      />
    </Flex>
  );
}
