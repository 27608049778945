import { useMutation, useQuery } from '@apollo/client';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Heading,
  Select,
  Input,
  ModalFooter,
  Button,
  Text,
  Box,
  Tooltip,
  Spinner,
  Checkbox,
} from '@chakra-ui/react';
import { TriggerField, TriggerFieldMatcher, ActionField, capitalize } from '@flume-finance/common';
import { useCallback, useEffect, useState } from 'react';
import { CreateAutomation, ICreateAutomation } from 'src/graphql/CreateAutomation';
import { CategorySelect } from 'src/components/transactions/CategorySelect';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  SimulateAutomationData,
  SimulateAutomationInput,
  SIMULATE_AUTOMATION,
} from 'src/graphql/SimulateAutomation';
import { debounce } from 'src/util';
import { useTranslation } from 'react-i18next';

const SPACE_MARGIN = 2;

export function CreateAutomationModal(props: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();

  const [triggerField, setTriggerField] = useState<TriggerField>(Object.values(TriggerField)[0]);
  const [triggerFieldMatcher, setTriggerFieldMatcher] = useState<TriggerFieldMatcher>(
    Object.values(TriggerFieldMatcher)[0],
  );
  const [triggerValue, setTriggerValue] = useState<string>('');
  const [overrideExistingCategories, setOverrideExistingCategories] = useState<boolean>(false);

  const [actionField, setActionField] = useState<ActionField>(Object.values(ActionField)[0]);
  const [actionFieldValue, setActionFieldValue] = useState<string | undefined>(undefined);

  const [mutate] = useMutation<ICreateAutomation['output'], ICreateAutomation['input']>(
    CreateAutomation.query,
    {
      update: CreateAutomation.update,
      optimisticResponse: CreateAutomation.optimisticResponse({
        triggerField,
        triggerFieldMatcher,
        triggerValue,
        actionField,
        actionFieldValue: actionFieldValue ?? '',
      }),
    },
  );

  const onClick = () => {
    mutate({
      variables: {
        createAutomationInput: {
          triggers: [
            {
              field: triggerField,
              matcher: triggerFieldMatcher,
              value: triggerValue,
            },
          ],
          actions: [
            {
              field: actionField,
              value: actionFieldValue ?? null,
            },
          ],
          overrideExistingCategories,
        },
      },
    });

    onClose();
  };

  const { isOpen, onClose } = props;

  // Reset the modal form when it is closed
  useEffect(() => {
    if (isOpen === false) {
      setOverrideExistingCategories(false);
      setTriggerValue('');
      setActionFieldValue(undefined);
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          {t('automations.createModal.title')}
          <Tooltip label={t('automations.createModal.description')} hasArrow>
            <QuestionOutlineIcon ml={2} w={4} h={4} />
          </Tooltip>
        </ModalHeader>
        <ModalBody>
          <Flex direction="column">
            <Heading size="sm" marginBottom={3} opacity={0.7}>
              {t('automations.createModal.if')}
            </Heading>
            <Flex>
              <Select
                variant="outline"
                marginX={SPACE_MARGIN}
                onChange={(e) => setTriggerField(e.currentTarget.value as TriggerField)}
                value={triggerField}
              >
                {Object.values(TriggerField).map((field) => (
                  <option key={field} value={field}>
                    {capitalize(field)}
                  </option>
                ))}
              </Select>
              <Select
                variant="outline"
                marginX={SPACE_MARGIN}
                value={triggerFieldMatcher}
                onChange={(e) =>
                  setTriggerFieldMatcher(e.currentTarget.value as TriggerFieldMatcher)
                }
              >
                {Object.values(TriggerFieldMatcher).map((field) => (
                  <option key={field} value={field}>
                    {capitalize(field)}
                  </option>
                ))}
              </Select>
              <Input
                variant="outline"
                placeholder={t('automations.createModal.triggerValuePlaceholder') ?? undefined}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                marginX={SPACE_MARGIN}
                value={triggerValue}
                onChange={(e) => setTriggerValue(e.currentTarget.value)}
              />
            </Flex>
            <Heading size="sm" mb={3} mt={5} opacity={0.7}>
              {t('automations.createModal.then')}
            </Heading>
            <Flex alignItems="center">
              <Select
                variant="outline"
                marginX={SPACE_MARGIN}
                flex={1}
                value={actionField}
                onChange={(e) => setActionField(e.currentTarget.value as ActionField)}
              >
                {Object.keys(ActionField).map((field) => (
                  <option key={field} value={field}>
                    {capitalize(field)}
                  </option>
                ))}
              </Select>
              <Text mx={SPACE_MARGIN} align="center" fontWeight="semibold">
                {t('automations.createModal.to')}
              </Text>
              <Box flex={1} mx={SPACE_MARGIN}>
                <CategorySelect
                  value={actionFieldValue}
                  onChange={(categoryId) => setActionFieldValue(categoryId)}
                  size={'md'}
                />
              </Box>
            </Flex>
            <Checkbox
              ml={2}
              mt={2}
              alignSelf="flex-end"
              isChecked={overrideExistingCategories}
              onChange={(e) => setOverrideExistingCategories(e.target.checked)}
            >
              {t('automations.createModal.overrideLabel')}
            </Checkbox>
          </Flex>
        </ModalBody>
        <ModalCloseButton />

        <ModalFooter display="flex" justifyContent="space-between" alignItems="center">
          <SimulateAutomation
            triggerField={triggerField}
            triggerFieldMatcher={triggerFieldMatcher}
            triggerValue={triggerValue}
            overrideExistingCategories={overrideExistingCategories}
          />
          <Button mr={3} onClick={onClick}>
            {t('automations.createModal.buttonText')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface SimulateAutomationProps {
  triggerField: TriggerField;
  triggerFieldMatcher: TriggerFieldMatcher;
  triggerValue: string;
  overrideExistingCategories: boolean;
}

const SimulateAutomation = ({
  triggerField,
  triggerFieldMatcher,
  triggerValue,
  overrideExistingCategories,
}: SimulateAutomationProps) => {
  const { t } = useTranslation();
  const [triggerValueDebounce, setTriggerValueDebounce] = useState<string>('');

  const debounceCb = useCallback(
    debounce<{ triggerValue: string }>((args) => {
      setTriggerValueDebounce(args.triggerValue);
    }, 500),
    [],
  );

  useEffect(() => debounceCb({ triggerValue }), [triggerValue]);
  const { loading, data } = useQuery<SimulateAutomationData, SimulateAutomationInput>(
    SIMULATE_AUTOMATION,
    {
      skip: [triggerField, triggerFieldMatcher, triggerValueDebounce].some((v) => v.length === 0),
      variables: {
        simulateAutomationInput: {
          triggers: [
            {
              field: triggerField,
              matcher: triggerFieldMatcher,
              value: triggerValueDebounce,
            },
          ],
          overrideExistingCategories,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loading) {
    return (
      <Flex justify="center" my={2}>
        <Spinner size="sm" />
      </Flex>
    );
  }

  const count = data?.simulateAutomation.count;

  if (count === undefined) {
    return <Box />;
  }

  return (
    <Text color="gray.500">{t('automations.createModal.affectedTransactionCount', { count })}</Text>
  );
};
