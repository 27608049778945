import { Divider, Flex, FlexProps, Text } from '@chakra-ui/react';

export const DividerWithText = (props: FlexProps) => {
  const { children, ...flexProps } = props;
  return (
    <Flex align="center" w="20rem" color="gray.300" {...flexProps}>
      <Divider flex={1} borderColor={'subtleText'} />
      <Text as="span" px="3" color={'subtleText'} fontWeight="medium">
        {children}
      </Text>
      <Divider flex={1} borderColor={'subtleText'} />
    </Flex>
  );
};
