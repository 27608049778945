import { gql } from 'src/graphql/__generated__/gql';
import { GraphqlMutation } from 'src/util/graphql';
import {
  CreateAssetMutation,
  CreateAssetMutationVariables,
} from 'src/graphql/__generated__/graphql';

export interface ICreateAsset {
  input: CreateAssetMutationVariables;
  output: CreateAssetMutation;
}

export const CreateAsset: GraphqlMutation<
  ICreateAsset,
  {
    id: string;
  }
> = {
  query: gql(/* GraphQL */ `
    mutation CreateAsset($name: String!, $type: AssetType!, $currentBalance: Bigint!, $currencyCode: String!) {
      createAsset(name: $name, type: $type, currentBalance: $currentBalance, currencyCode: $currencyCode) {
        id
      }
    }
  `),
  optimisticResponse: ({ id }) => ({
    createAsset: {
      id,
    },
  }),
  update: (cache, { data }, { variables }) => {
    if (data === undefined || data == null || variables === undefined) {
      return;
    }

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
  },
};
