import { Tag, useColorModeValue, Text, Skeleton, Icon, ThemingProps } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { capitalize } from '@flume-finance/common';
import { theme } from '@flume-finance/ui';
import { useQuery } from '@apollo/client';
import {
  GetCategoryGroupsData,
  GET_CATEGORY_GROUPS,
  GET_CATEGORY_GROUPS_PLACEHOLDER,
} from 'src/graphql/GetCategoryGroups';
import CategoryIcon from 'src/assets/icons/navbar/icons8-tags.svg?react';

export function CategoryTag({
  categoryId,
  size = 'md',
  hideIcon = false,
  hideLeftRadius = false,
}: {
  categoryId: string | undefined;
  size?: ThemingProps<'Tag'>['size'];
  hideIcon?: boolean;
  hideLeftRadius?: boolean;
}) {
  const { loading, data } = useQuery<GetCategoryGroupsData>(GET_CATEGORY_GROUPS);

  const isLoaded = !(loading || data === undefined);
  const categoryGroups =
    isLoaded && data ? data?.categoryGroups : GET_CATEGORY_GROUPS_PLACEHOLDER.categoryGroups;

  const categories = categoryGroups.flatMap((cg) => cg.categories);
  const category = categories.find((c) => c.id === categoryId);

  const fontColor = useColorModeValue(
    `${category?.color ?? 'gray'}.800`,
    `${category?.color ?? 'gray'}.200`,
  );
  const bgColor = transparentize(`${category?.color ?? 'gray'}.400`, 0.4)(theme);

  const categoryName = category?.name ?? 'Uncategorized';

  return (
    <Skeleton isLoaded={isLoaded} borderLeftRadius={0} alignItems={'center'} display={'flex'}>
      <Tag
        bg={bgColor}
        color={fontColor}
        gap={1}
        borderLeftRadius={hideLeftRadius ? 0 : undefined}
        size={size}
        py={0.5}
        px={2}
      >
        {!hideIcon && <Icon as={CategoryIcon} fill={'coloredIconColor'} />}
        <Text isTruncated fontSize={size}>
          {capitalize(categoryName)}
        </Text>
      </Tag>
    </Skeleton>
  );
}
