import { Tag } from '@chakra-ui/react';
import { formatCurrency } from 'src/util';
import { StripePrice } from './Price';

interface DiscountTagProps {
  prices: StripePrice[];
  price: StripePrice;
}
export const DiscountTag = ({ prices, price }: DiscountTagProps) => {
  const yearlyPrice = prices.find((p) => p.recurrenceInterval === 'YEARLY');
  const monthlyPrice = prices.find((p) => p.recurrenceInterval === 'MONTHLY');

  if (yearlyPrice === undefined || monthlyPrice === undefined) {
    return null;
  }

  const discount =
    price.recurrenceInterval === 'YEARLY' ? monthlyPrice.amount * 12 - yearlyPrice.amount : 0;

  return (
    <Tag colorScheme={discount > 0 ? 'blue' : undefined}>{`Save ${formatCurrency({
      cents: discount,
      hideCents: true,
      currencyCode: yearlyPrice.currency,
    })}`}</Tag>
  );
};
