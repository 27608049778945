import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { REDIRECT_REASONS } from 'src/components/login/LoginApp';
import { DELETE_USER, DeleteUserData } from 'src/graphql/DeleteUser';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';
import { handleLogout } from 'src/util/authentication';

export const DeleteAccountButton = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkboxes = [
    t('settings.account.deleteAccountModal.checklist.backup'),
    t('settings.account.deleteAccountModal.checklist.notRecoverable'),
    t('settings.account.deleteAccountModal.checklist.subscription'),
  ];

  const [checkboxStates, setCheckboxStates] = useState<boolean[]>(
    new Array(checkboxes.length).fill(false),
  );

  const [emailConfirmationInvalid, setEmailConfirmationInvalid] = useState<boolean>(true);

  const navigate = useNavigate();
  const [deleteUser, { loading }] = useMutation<DeleteUserData>(DELETE_USER, {
    onCompleted: () => {
      handleLogout({ params: { redirectReason: REDIRECT_REASONS.USER_DELETION }, navigate });
    },
  });

  return (
    <>
      <Button colorScheme={'red'} maxW="fit-content" variant={'outline'} onClick={onOpen}>
        Delete my account
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="md">{t('settings.account.deleteAccountModal.title')}</Heading>
          </ModalHeader>
          <ModalBody gap={4} display="flex" flexDirection={'column'}>
            <Alert status="warning" borderRadius={'lg'} fontSize="sm" size="sm">
              <AlertIcon />
              {t('settings.account.deleteAccountModal.warningText')}
            </Alert>
            <Text>{t('settings.account.deleteAccountModal.description', { email: 'todo' })}</Text>
            <Flex flexDirection={'column'} gap={2}>
              <CheckboxGroup colorScheme="blue" isDisabled={loading}>
                {checkboxes.map((cb, i) => (
                  <Checkbox
                    key={cb}
                    tabIndex={0}
                    onChange={(e) =>
                      setCheckboxStates([
                        ...checkboxStates.slice(0, i),
                        e.target.checked,
                        ...checkboxStates.slice(i + 1),
                      ])
                    }
                  >
                    {cb}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Flex>
            <EmailConfirmation setInvalid={setEmailConfirmationInvalid} isDisabled={loading} />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              colorScheme={'red'}
              isDisabled={checkboxStates.some((cbs) => cbs === false) || emailConfirmationInvalid}
              isLoading={loading}
              onClick={() => deleteUser()}
            >
              {t('settings.account.deleteAccountModal.action.delete')}
            </Button>
            <Button colorScheme={'gray'} onClick={onClose} isDisabled={loading}>
              {t('settings.account.deleteAccountModal.action.cancel')}
            </Button>
          </ModalFooter>
          <ModalCloseButton tabIndex={-1} />
        </ModalContent>
      </Modal>
    </>
  );
};

const EmailConfirmation = ({
  setInvalid,
  isDisabled,
}: {
  setInvalid: (invalid: boolean) => void;
  isDisabled: boolean;
}) => {
  const { t } = useTranslation();

  const { data: currentUserData } = useQuery(GET_CURRENT_USER, {
    onCompleted: getCurrentUserCallback,
  });

  const [email, setEmail] = useState<string>('');
  const USER_EMAIL = currentUserData?.currentUser.email;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormControl
      id="emailDeleteConfirmation"
      isInvalid={email !== USER_EMAIL && email.length > 0 && !isFocused}
      isDisabled={isDisabled}
    >
      <FormLabel>
        {t('settings.account.deleteAccountModal.emailConfirmation.label', {
          email: USER_EMAIL,
        })}
      </FormLabel>
      <Input
        placeholder={USER_EMAIL}
        type="email"
        autoComplete="username"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setInvalid(e.target.value !== USER_EMAIL);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <FormErrorMessage>
        {t('settings.account.deleteAccountModal.emailConfirmation.errorText')}
      </FormErrorMessage>
    </FormControl>
  );
};
