import { Button, Flex, FormControl, FormHelperText, Icon } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCloudUpload, MdLock, MdTrendingUp } from 'react-icons/md';
import VenmoIcon from 'src/assets/icons/logos/venmo.svg?react';
import { DividerWithText } from 'src/components/shared/DividerWithText';
import { CreateAccountType } from './CreateAccountModal';

interface ChooseAccountTypeProps {
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setAccountType: Dispatch<SetStateAction<CreateAccountType>>;
}

export const ChooseAccountType = ({ setActiveIndex, setAccountType }: ChooseAccountTypeProps) => {
  const { t } = useTranslation();
  return (
    <Flex direction="column" alignItems="center" justifyContent={'center'} gap={4}>
      <FormControl display="flex" flexDir="column" alignItems={'center'} maxWidth={'20rem'}>
        <Button
          leftIcon={<MdLock size={20} />}
          onClick={() => {
            setActiveIndex(1);
            setAccountType(CreateAccountType.Automatic);
          }}
          aria-label="Link with Bank"
          width="20rem"
        >
          Setup Automatic Account
        </Button>
        <FormHelperText>Automatically import transactions from your bank</FormHelperText>
      </FormControl>
      <DividerWithText maxW={200}>or</DividerWithText>
      <FormControl flexDir="column" alignItems={'center'} width="20rem">
        <Button
          colorScheme={'gray'}
          variant="outline"
          leftIcon={<MdCloudUpload size={20} />}
          onClick={() => {
            setActiveIndex(1);
            setAccountType(CreateAccountType.Manual);
          }}
          aria-label="Setup Manual Account"
          width={'20rem'}
        >
          Import Account Transactions
        </Button>
        <FormHelperText>Manually import transactions from CSV files</FormHelperText>
      </FormControl>
      <FormControl flexDir="column" alignItems={'center'} width="20rem">
        <Button
          colorScheme={'gray'}
          variant="outline"
          leftIcon={<MdTrendingUp size={20} />}
          onClick={() => {
            setActiveIndex(1);
            setAccountType(CreateAccountType.Asset);
          }}
          aria-label="Setup Manual Account"
          width={'full'}
        >
          Track Assets or Debts
        </Button>
        <FormHelperText>
          Manually track investments, retirement accounts, and real estate
        </FormHelperText>
      </FormControl>
      <FormControl flexDir="column" alignItems={'center'} width="20rem">
        <Button
          colorScheme={'gray'}
          variant="outline"
          leftIcon={<Icon as={VenmoIcon} fill="textColor" />}
          onClick={() => {
            setActiveIndex(1);
            setAccountType(CreateAccountType.Email);
          }}
          aria-label={t('accounts.createAutomaticModal.venmo.ariaLabel')}
          width={'full'}
        >
          {t('accounts.createAutomaticModal.venmo.buttonText')}
        </Button>
        <FormHelperText>{t('accounts.createAutomaticModal.venmo.helperText')}</FormHelperText>
      </FormControl>
    </Flex>
  );
};
