import { useMutation, useQuery } from '@apollo/client';
import { ActionField, TriggerFieldMatcher } from '@flume-finance/common';
import { useCallback, useEffect } from 'react';
import { CreateAutomation, ICreateAutomation } from 'src/graphql/CreateAutomation';
import { DeleteAutomation, IDeleteAutomaton } from 'src/graphql/DeleteAutomation';
import {
  GetPendingAutomationSuggestionsData,
  GET_PENDING_AUTOMATION_SUGGESTIONS,
  GET_PENDING_AUTOMATION_SUGGESTIONS_PLACEHOLDER,
} from 'src/graphql/GetAutomationSuggestions';
import {
  IUpdateAutomationSuggestion,
  UpdateAutomationSuggestion,
} from 'src/graphql/UpdateAutomationSuggestion';
import { getQueryData } from 'src/util/graphql';
import { AutomationSuggestionCard } from 'src/components/categorize/cards/AutomationSuggestionCard';
import { Action, CategorizeSection } from 'src/components/categorize/CategorizeSection';
import { useTranslation } from 'react-i18next';
import DoneIcon from 'src/assets/icons/icons8-done.svg?react';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { Flex, Spacer } from '@chakra-ui/react';

interface AutomationSuggestionSectionProps {
  onComplete: () => void;
}

export const AutomationSuggestionReviewSection = ({
  onComplete,
}: AutomationSuggestionSectionProps) => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery<GetPendingAutomationSuggestionsData>(
    GET_PENDING_AUTOMATION_SUGGESTIONS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const {
    isInitialLoaded,
    queryData: { pendingAutomationSuggestions },
  } = getQueryData(data, GET_PENDING_AUTOMATION_SUGGESTIONS_PLACEHOLDER);

  const [createAutomation] = useMutation<ICreateAutomation['output'], ICreateAutomation['input']>(
    CreateAutomation.query,
    {
      update: CreateAutomation.update,
    },
  );

  const [deleteAutomation] = useMutation<IDeleteAutomaton['output'], IDeleteAutomaton['input']>(
    DeleteAutomation.query,
    {
      update: DeleteAutomation.update,
    },
  );

  const [updateAutomationSuggestionStatus] = useMutation<
    IUpdateAutomationSuggestion['output'],
    IUpdateAutomationSuggestion['input']
  >(UpdateAutomationSuggestion.query);

  const updateEntity = useCallback(
    (mode: Action, entityId: string, categoryId?: string) => {
      const suggestion = pendingAutomationSuggestions.find((pas) => pas.id === entityId);
      if (suggestion === undefined) {
        throw 'Automation Suggestion not found';
      }

      switch (mode) {
        case 'review': {
          if (categoryId) {
            createAutomation({
              variables: {
                createAutomationInput: {
                  triggers: [
                    {
                      field: suggestion.field,
                      matcher: TriggerFieldMatcher.EQUALS,
                      value: suggestion.value,
                    },
                  ],
                  actions: [
                    {
                      field: ActionField.CATEGORY,
                      value: categoryId,
                    },
                  ],
                  // TODO: [UX] This is needed to properly support the undo functionality, but there might be a better way to handle it
                  overrideExistingCategories: true,
                },
              },
              optimisticResponse: CreateAutomation.optimisticResponse({
                triggerField: suggestion?.field,
                triggerFieldMatcher: TriggerFieldMatcher.EQUALS,
                triggerValue: suggestion.value,
                actionField: ActionField.CATEGORY,
                actionFieldValue: categoryId,
              }),
              onCompleted: ({ createAutomation: { id } }) => {
                const input = {
                  id: entityId,
                  status: 'ACCEPTED' as const,
                  automationId: id,
                };

                updateAutomationSuggestionStatus({
                  variables: {
                    updateAutomationSuggestionInput: input,
                  },
                  optimisticResponse: UpdateAutomationSuggestion.optimisticResponse({
                    ...input,
                    field: suggestion.field,
                    value: suggestion.value,
                  }),
                });
              },
            });
          }
          break;
        }
        case 'undo': {
          if (suggestion.automation) {
            deleteAutomation({
              variables: { automationId: suggestion.automation.id },
              optimisticResponse: DeleteAutomation.optimisticResponse({
                automationId: suggestion.automation.id,
              }),
            });
          }

          updateAutomationSuggestionStatus({
            variables: {
              updateAutomationSuggestionInput: {
                id: entityId,
                status: 'PENDING',
                automationId: null,
              },
            },
            optimisticResponse: UpdateAutomationSuggestion.optimisticResponse({
              id: entityId,
              status: 'PENDING',
              field: suggestion.field,
              value: suggestion.value,
              automationId: null,
            }),
          });

          break;
        }
        case 'skip': {
          updateAutomationSuggestionStatus({
            variables: {
              updateAutomationSuggestionInput: {
                id: entityId,
                status: 'REJECTED',
                automationId: null,
              },
            },
            optimisticResponse: UpdateAutomationSuggestion.optimisticResponse({
              id: entityId,
              status: 'REJECTED',
              field: suggestion.field,
              value: suggestion.value,
              automationId: null,
            }),
          });

          break;
        }
      }
    },
    [
      pendingAutomationSuggestions,
      createAutomation,
      deleteAutomation,
      updateAutomationSuggestionStatus,
    ],
  );

  const fetchMore = () => {
    // NOTE: This endpoint is not currently paginated
  };

  // Automatically change to transaction categorize section if there are no automation suggestions
  useEffect(() => {
    if (pendingAutomationSuggestions.length === 0) {
      onComplete();
    }
  }, [pendingAutomationSuggestions, onComplete]);

  if (error) {
    return (
      <Flex flexDir={'column'}>
        <Spacer flexBasis={'30%'} />
        <ErrorWithRefetch
          message="There was an issue loading you automation suggestions"
          refetch={refetch}
        />
        <Spacer flexBasis={'70%'} />
      </Flex>
    );
  }

  return (
    <CategorizeSection
      entities={pendingAutomationSuggestions}
      CompleteComponent={
        <EmptyListCta
          title={t('categorize.automationSuggestions.emptyListCta.title')}
          description={t('categorize.automationSuggestions.emptyListCta.description')}
          Icon={DoneIcon}
          buttonText={t('categorize.automationSuggestions.emptyListCta.buttonText')}
          onClick={onComplete}
        />
      }
      entityType={'automationSuggestions'}
      total={pendingAutomationSuggestions.length}
      isLoaded={isInitialLoaded && !loading && pendingAutomationSuggestions.length > 0}
      updateEntity={updateEntity}
      fetchMore={fetchMore}
      Card={AutomationSuggestionCard}
    />
  );
};
