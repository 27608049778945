import { useMutation } from '@apollo/client';
import { Button, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { RiBankFill } from 'react-icons/ri';
import {
  PlaidLinkOnExit,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { plaidItemConnection } from 'src/cache';
import {
  ExchangePublicTokenData,
  ExchangePublicTokenInput,
  EXCHANGE_PUBLIC_TOKEN,
} from 'src/graphql/ExchangePublicToken';

interface PlaidLinkButtonProps {
  linkToken: string | null;
  onClose: () => void;
  setPlaidLinkOpen: (open: boolean) => void;
  isError: boolean;
}

export const PlaidLinkButton = ({
  linkToken,
  onClose,
  isError,
  setPlaidLinkOpen,
}: PlaidLinkButtonProps) => {
  const [exchangePublicToken] = useMutation<ExchangePublicTokenData, ExchangePublicTokenInput>(
    EXCHANGE_PUBLIC_TOKEN,
  );

  const onSuccess = useCallback(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      exchangePublicToken({
        variables: {
          publicToken,
        },
      }).then((res) => {
        const itemId = res.data?.exchangePublicToken.itemId;

        // TODO: Better error here
        if (itemId === undefined) {
          // TODO: Display a toast here saying there was an issue establishing a connection (although it might have succeeded in the backend)
          throw new Error('There was an issue connecting to your bank');
        }

        plaidItemConnection({
          itemId,
          institutionName: metadata.institution?.name ?? 'your bank',
          status: 'importing',
        });
      });

      setPlaidLinkOpen(false);
      onClose();
    },
    [exchangePublicToken, onClose],
  );

  const onExit = useCallback<PlaidLinkOnExit>((_error: Parameters<PlaidLinkOnExit>[0]) => {
    setPlaidLinkOpen(false);
    localStorage.removeItem('plaidLinkToken');
  }, []);

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <>
      <Button
        onClick={() => {
          open();
          setPlaidLinkOpen(true);
        }}
        leftIcon={<RiBankFill size={20} />}
        isLoading={!ready && !isError}
        isDisabled={!ready || isError}
        width={'20rem'}
        aria-label="Connect to Bank"
      >
        Connect to Bank
      </Button>
      {isError && (
        <Text color="red.500" fontSize="sm">
          Failed to establish a connection
        </Text>
      )}
    </>
  );
};
