import { UnorderedList, ListItem, createStandaloneToast, ColorMode } from '@chakra-ui/react';
import { theme } from '@flume-finance/ui';
import { ImportStatementsMutation, FileResponseStatus } from 'src/graphql/__generated__/graphql';

const ToastDescription = (props: {
  files: ImportStatementsMutation['importStatements'];
  message: string;
}) => {
  return (
    <div>
      <p>{props.message}</p>
      <UnorderedList>
        {props.files.map((f) => (
          <ListItem key={f.filename}>{f.filename}</ListItem>
        ))}
      </UnorderedList>
    </div>
  );
};

export const displayFileToasts = (
  fileResponse: ImportStatementsMutation['importStatements'],
  colorMode: ColorMode | undefined,
) => {
  const { toast } = createStandaloneToast({ theme, colorMode });
  const mapping: Record<FileResponseStatus, ImportStatementsMutation['importStatements']> = {
    [FileResponseStatus.Success]: [],
    [FileResponseStatus.Duplicate]: [],
    [FileResponseStatus.Error]: [],
    [FileResponseStatus.Unsupported]: [],
  };

  for (const d of fileResponse) {
    mapping[d.status].push(d);
  }

  if (mapping[FileResponseStatus.Duplicate].length > 0) {
    toast({
      title: 'Statement Duplicate.',
      description: (
        <ToastDescription
          message="The following statements have already been imported:"
          files={mapping[FileResponseStatus.Duplicate]}
        />
      ),
      status: 'warning',
      duration: 10_000,
      isClosable: true,
    });
  }

  if (mapping[FileResponseStatus.Success].length > 0) {
    toast({
      title: 'Statement Import Complete.',
      description: (
        <ToastDescription
          message="All transactions from the following statements have been imported:"
          files={mapping[FileResponseStatus.Success]}
        />
      ),
      status: 'success',
      duration: 5_000,
      isClosable: true,
    });
  }

  if (mapping[FileResponseStatus.Error].length > 0) {
    toast({
      title: 'Statement Import Error.',
      description: (
        <ToastDescription
          message="The following statements failed to import:"
          files={mapping[FileResponseStatus.Error]}
        />
      ),
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }
};
