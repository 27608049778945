import { useQuery, useReactiveVar } from '@apollo/client';
import { Alert, AlertIcon, Flex, Heading, Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { subscriptionIndicatorInfo } from 'src/cache';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import {
  GetActiveSubscriptionData,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ACTIVE_SUBSCRIPTION_PLACEHOLDER,
} from 'src/graphql/GetActiveSubscription';
import {
  GetStripeProductsData,
  GET_STRIPE_PRODUCTS,
  GET_STRIPE_PRODUCTS_PLACEHOLDER,
} from 'src/graphql/GetStripeProducts';

import { ActiveSubscription } from './ActiveSubscription';
import { Product } from './checkout/Product';

export const BillingPage = () => {
  const { t } = useTranslation();
  const subscriptionIndicatorInfoValue = useReactiveVar(subscriptionIndicatorInfo);
  const {
    data: activeSubData,
    loading: activeSubQueryLoading,
    error: activeSubscriptionError,
  } = useQuery<GetActiveSubscriptionData>(GET_ACTIVE_SUBSCRIPTION);
  const activeSubLoading =
    activeSubQueryLoading || subscriptionIndicatorInfoValue?.status === 'info';
  const activeSubscription =
    activeSubLoading || activeSubData === undefined
      ? GET_ACTIVE_SUBSCRIPTION_PLACEHOLDER.activeSubscription
      : activeSubData?.activeSubscription;

  const { data, loading } = useQuery<GetStripeProductsData>(GET_STRIPE_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
  });

  const products =
    loading || data === undefined
      ? GET_STRIPE_PRODUCTS_PLACEHOLDER.stripeProducts
      : data.stripeProducts;

  let content: JSX.Element;
  if (activeSubscriptionError) {
    content = <ErrorWithRefetch message={t('billing.subscriptions.errorLoadingMessage')} />;
  } else if (activeSubscription && activeSubscription.status !== 'TRIALING') {
    content = (
      <Flex gap={4} flexDir="column" width="full" maxWidth={'60ch'}>
        <ActiveSubscription data={activeSubscription} loading={activeSubLoading} />
      </Flex>
    );
  } else {
    content = (
      <Flex flexDir="column" justifyContent={'center'} w="full" maxW="60ch" gap={4}>
        {activeSubscription?.status === 'TRIALING' && activeSubscription.cancelAt === null && (
          <Skeleton isLoaded={!activeSubLoading}>
            <Alert status={'warning'} variant="left-accent" width="full">
              <AlertIcon />
              {t('billing.products.trialMessageText', {
                date: activeSubscription.currentPeriodEnd?.toLocaleDateString(),
              })}
            </Alert>
          </Skeleton>
        )}
        <Flex flex={1} align="center">
          {products.map((sp) => (
            <Product key={sp.id} product={sp} loading={loading} eligibleForTrial={false} />
          ))}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDir={'column'} alignItems={'center'} gap={6}>
      <Heading alignSelf={'flex-start'}>{t('billing.title')}</Heading>
      {content}
    </Flex>
  );
};
