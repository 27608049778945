import { gql } from '@apollo/client';
import { GraphqlMutation } from 'src/util/graphql';

export interface ISetTransactionPayee {
  input: {
    transactionId: string;
    payee: string;
  };
  output: {
    setTransactionPayee: {
      affectedTransactionIds: string[];
    };
  };
}

export const SetTransactionPayee: GraphqlMutation<
  ISetTransactionPayee,
  {
    affectedTransactionIds: string[];
  }
> = {
  query: gql`
    mutation SetTransactionPayee($transactionId: String!, $payee: String!) {
      setTransactionPayee(transactionId: $transactionId, payee: $payee) {
        affectedTransactionIds
      }
    }
  `,
  optimisticResponse: ({ affectedTransactionIds }) => ({
    setTransactionPayee: {
      affectedTransactionIds,
    },
  }),
  update: (cache, { data }, { variables }) => {
    if (data === undefined || data == null || variables === undefined) {
      return;
    }

    // Update the payee in the cache of all affected transactions
    data.setTransactionPayee.affectedTransactionIds.map((tId) => {
      cache.writeFragment({
        id: `Transaction:${tId}`,
        data: { id: tId, payee: variables.payee },
        fragment: gql`
          fragment UpdatePayee on Transaction {
            id
            payee
          }
        `,
      });
    });
  },
};
