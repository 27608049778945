import { useQuery } from '@apollo/client';
import { Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Product } from 'src/components/settings/billing/checkout/Product';
import { SubscriptionLoadingIndicator } from 'src/components/settings/billing/checkout/SubscriptionLoadingIndicator';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';
import {
  GET_STRIPE_PRODUCTS,
  GET_STRIPE_PRODUCTS_PLACEHOLDER,
  GetStripeProductsData,
} from 'src/graphql/GetStripeProducts';

export function SubscriptionApp() {
  const { t } = useTranslation();

  const { data: currentUserData, loading: currentUserLoading } = useQuery(GET_CURRENT_USER, {
    onCompleted: getCurrentUserCallback,
  });
  const eligibleForTrial = currentUserData?.currentUser.stripeCustomer.subscriptions.length === 0;

  const { data, loading, error, refetch } = useQuery<GetStripeProductsData>(GET_STRIPE_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
  });

  const products =
    loading || data === undefined
      ? GET_STRIPE_PRODUCTS_PLACEHOLDER.stripeProducts
      : data.stripeProducts;

  return (
    <Flex
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      height="100%"
      flexDir={'column'}
      p={{ base: 2, sm: 0 }}
    >
      <Flex gap={4} flexDir="column" width="full" maxWidth={'60ch'}>
        {error ? (
          <ErrorWithRefetch message={t('billing.products.errorLoadingMessage')} refetch={refetch} />
        ) : (
          <>
            <Heading>{t('billing.products.title')}</Heading>
            <Flex align="center" justifyContent={'center'}>
              {products.map((sp) => (
                <Product
                  key={sp.id}
                  product={sp}
                  loading={loading || currentUserLoading}
                  eligibleForTrial={eligibleForTrial}
                />
              ))}
            </Flex>
          </>
        )}
      </Flex>
      <SubscriptionLoadingIndicator />
    </Flex>
  );
}
