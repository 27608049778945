import { useMutation, useQuery } from '@apollo/client';
import { Button, Flex, Container, Box, Spacer } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import {
  CreateCategoryGroupData,
  CreateCategoryGroupInput,
  CREATE_CATEGORY_GROUP,
  updateCategoryGroupCache,
} from 'src/graphql/CreateCategoryGroup';
import {
  GetCategoryGroupsData,
  GET_CATEGORY_GROUPS,
  GET_CATEGORY_GROUPS_PLACEHOLDER,
} from 'src/graphql/GetCategoryGroups';
import { CategoryGroupEdit } from './CategoryGroupEdit';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { SyncIndicator } from 'src/components/shared/SyncIndicator';
import { AppRoot } from 'src/components/shared/AppRoot';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import CategoryIcon from 'src/assets/icons/icons8-tags.svg?react';
import { useTranslation } from 'react-i18next';
import { HeadingWithMenu } from 'src/components/shared/HeadingWithMenu';

export function CategoryApp() {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery<GetCategoryGroupsData>(GET_CATEGORY_GROUPS);

  const isLoaded = !(loading || data === undefined);
  const categoryGroups =
    isLoaded && data ? data?.categoryGroups : GET_CATEGORY_GROUPS_PLACEHOLDER.categoryGroups;

  const [createCategoryGroup, { loading: createLoading }] = useMutation<
    CreateCategoryGroupData,
    CreateCategoryGroupInput
  >(CREATE_CATEGORY_GROUP, {
    update: updateCategoryGroupCache,
    variables: {
      name: t('categories.defaultCategoryGroupName'),
    },
  });

  const EmptyComponent = () => (
    <Container
      display={'flex'}
      hidden={categoryGroups.length > 0}
      flexGrow={1}
      maxW="100ch"
      boxShadow={'md'}
      flexDirection="column"
    >
      <Spacer flexBasis={'30%'} />
      <EmptyListCta
        title={t('categories.emptyListCta.title')}
        description={t('categories.emptyListCta.description')}
        Icon={CategoryIcon}
        buttonText={t('categories.emptyListCta.buttonText')}
        isLoading={createLoading}
        onClick={() => createCategoryGroup()}
      />
      <Spacer flexBasis={'70%'} />
    </Container>
  );

  const categories = categoryGroups.map((cg) => (
    <CategoryGroupEdit key={cg.id} categoryGroup={cg} isLoaded={isLoaded} />
  ));

  const Header = () => (
    <Box
      display={'flex'}
      boxShadow={'none'}
      maxW={'initial'}
      justifyContent="space-between"
      alignItems="flex-start"
      position="sticky"
    >
      <Flex justifyContent="center" gap={2}>
        <HeadingWithMenu />
        <SyncIndicator />
      </Flex>
      {!error && (
        <Button
          colorScheme={'gray'}
          variant={'outline'}
          boxShadow="base"
          leftIcon={<AddIcon w={3} h={3} />}
          my={1}
          alignSelf="flex-end"
          size="sm"
          isLoading={createLoading}
          onClick={() => createCategoryGroup()}
        >
          {t('categories.createButtonText')}
        </Button>
      )}
    </Box>
  );

  return (
    <AppRoot>
      <Flex flexDirection="column" flexGrow={1} maxW="100ch" gap={{ base: 2, md: 4 }}>
        <Header />
        <EmptyComponent />
        <Container
          display="flex"
          variant="card"
          flexDirection="column"
          flexGrow={1}
          maxW="100ch"
          boxShadow={'md'}
          height="min-content"
          overflow="auto"
          p={4}
          hidden={categoryGroups.length === 0}
        >
          {error ? <ErrorWithRefetch message={t('categories.loadErrorMessage')} /> : categories}
        </Container>
      </Flex>
    </AppRoot>
  );
}
