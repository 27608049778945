import { gql } from '@apollo/client';
import { TriggerField } from '@flume-finance/common';
import { GraphqlMutation } from 'src/util/graphql';

export interface IUpdateAutomationSuggestion {
  input: {
    updateAutomationSuggestionInput: {
      id: string;
      status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
      automationId: string | null;
    };
  };
  output: {
    updateAutomationSuggestion: {
      __typename: 'AutomationSuggestion';
      id: string;
      field: TriggerField;
      value: string;
      status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
      automation: { id: string; __typename: 'Automation' } | null;
    };
  };
}

export const UpdateAutomationSuggestion: GraphqlMutation<
  IUpdateAutomationSuggestion,
  {
    id: string;
    field: TriggerField;
    value: string;
    status: IUpdateAutomationSuggestion['output']['updateAutomationSuggestion']['status'];
    automationId: string | null;
  }
> = {
  query: gql`
    mutation UpdateAutomationSuggestion(
      $updateAutomationSuggestionInput: UpdateAutomationSuggestionInput!
    ) {
      updateAutomationSuggestion(
        updateAutomationSuggestionInput: $updateAutomationSuggestionInput
      ) {
        id
        field
        value
        status
        automation {
          id
        }
      }
    }
  `,
  optimisticResponse: ({ id, status, automationId, field, value }) => ({
    updateAutomationSuggestion: {
      __typename: 'AutomationSuggestion' as const,
      id,
      field,
      value,
      status,
      automation: automationId
        ? {
            id: automationId,
            __typename: 'Automation' as const,
          }
        : null,
    },
  }),
};
