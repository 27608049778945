import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  RegisterNotificationSubscription,
  IRegisterNotificationSubscription,
} from 'src/graphql/RegisterNotificationSubscription';

export const requiredCapabilities: [string, Record<string, any>][] = [
  ['serviceWorker', navigator],
  ['Notification', window],
  ['PushManager', window],
];

export const isNotificationSupported = (): boolean => {
  return requiredCapabilities.every(([key, map]) => key in map);
};

const registerSubscription = async (): Promise<
  { subscription: PushSubscription; input: IRegisterNotificationSubscription['input'] } | undefined
> => {
  if (!isNotificationSupported()) {
    console.error('Push notifications are not supported by this browser.');
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();

  if (registration === undefined) {
    console.error('Failed to find existing service worker registration.');
    return;
  }

  // Prompt for notification permission
  const permission = await Notification.requestPermission();

  if (permission !== 'granted') {
    console.error('Notification permission not granted');
    return;
  }

  // Subscribe the service worker to web push
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: import.meta.env.VITE_WEB_PUSH_VAPID_PUBLIC_KEY,
  });

  const subJSON = subscription.toJSON();
  if (
    subJSON.endpoint === undefined ||
    subJSON?.keys?.p256dh === undefined ||
    subJSON?.keys.auth === undefined
  ) {
    return;
  }

  return {
    subscription,
    input: {
      endpoint: subJSON.endpoint,
      p256dh: subJSON.keys.p256dh,
      auth: subJSON.keys.auth,
    },
  };
};

export const useNotificationSubscription = (): {
  subscription: PushSubscription | null | undefined;
  loading: boolean;
  unsubscribe: () => void;
  subscribe: () => void;
} => {
  const [subscription, setSubscription] = useState<PushSubscription | null | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [persistSubscription] = useMutation<
    IRegisterNotificationSubscription['output'],
    IRegisterNotificationSubscription['input']
  >(RegisterNotificationSubscription.query);

  useEffect(() => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration === undefined) {
        setSubscription(null);
        return;
      }

      registration.pushManager.getSubscription().then((sub) => setSubscription(sub));
    });
  }, []);

  const subscribe = async () => {
    setLoading(true);
    const res = await registerSubscription();
    if (res === undefined) {
      return;
    }
    await persistSubscription({
      variables: res.input,
    });
    setLoading(false);
    setSubscription(res.subscription);
  };

  const unsubscribe = async () => {
    setLoading(true);
    await subscription?.unsubscribe();
    setLoading(false);
    setSubscription(null);
  };

  return { subscription, unsubscribe, subscribe, loading };
};

export const useNotificationPermission = () => {
  const [permission, setPermission] = useState<PermissionState | undefined>(undefined);

  useEffect(() => {
    navigator.permissions.query({ name: 'notifications' }).then((permissionStatus) => {
      setPermission(permissionStatus.state);

      permissionStatus.onchange = () => {
        setPermission(permissionStatus.state);
      };
    });
  }, []);

  return permission;
};
