import * as Sentry from '@sentry/react';
import { Crisp } from 'crisp-sdk-web';
import { gql } from 'src/graphql/__generated__/gql';
import { GetCurrentUserQuery } from 'src/graphql/__generated__/graphql';

export const GET_CURRENT_USER = gql(/* GraphQL */ `
  query GetCurrentUser {
    currentUser {
      id
      name
      email
      isEmailVerified
      currencyCode
      stripeCustomer {
        subscriptions {
          id
          status
        }
      }
    }
  }
`);

export const getCurrentUserCallback = ({ currentUser }: GetCurrentUserQuery) => {
  // Tag the current user in Sentry for all errors/ traces
  Sentry.setUser({ id: currentUser.id, email: currentUser.email });

  // Set the user metadata on the Crisp session
  Crisp.user.setEmail(currentUser.email);
  Crisp.user.setNickname(currentUser.name);
  Crisp.session.setData({
    id: currentUser.id,
  });
};
