import { Text, Heading, Tag, Flex, useColorModeValue } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { useQuery } from '@apollo/client';
import {
  GET_RECURRING_TRANSACTIONS,
  GET_RECURRING_TRANSACTIONS_PLACEHOLDER,
  GetRecurringTransactionsData,
} from 'src/graphql/GetRecurringTransactions';
import { SharedTable } from 'src/components/shared/SharedTable';
import { useMemo } from 'react';
import { theme } from '@flume-finance/ui';

type ColumnData = GetRecurringTransactionsData['recurringTransactions'][0];
type ColumnDefs = ColumnDef<ColumnData, any>[];

function RecurringTransactionTable() {
  const { loading, data, error } = useQuery<GetRecurringTransactionsData>(
    GET_RECURRING_TRANSACTIONS,
  );
  const disabledTextColor = useColorModeValue('gray.400', 'gray.500');

  const isLoaded = !(loading || data === undefined);
  let recurringTransactions =
    isLoaded && data
      ? data?.recurringTransactions
      : GET_RECURRING_TRANSACTIONS_PLACEHOLDER.recurringTransactions;

  recurringTransactions = recurringTransactions.slice().sort((a, b) => {
    return Number(b.active) - Number(a.active);
  });

  const columnHelper = createColumnHelper<ColumnData>();

  const columns = useMemo<ColumnDefs>(
    () => [
      columnHelper.accessor((t) => t.payee, {
        id: 'payee',
        header: 'Payee',
        // width: 70,
        sortDescFirst: true,
        cell: (props): JSX.Element => {
          const transaction = props.row.original;
          return useMemo(
            () => (
              <Text
                fontSize="l"
                fontWeight="semibold"
                color={transaction.active ? '' : disabledTextColor}
              >
                {transaction.payee}
              </Text>
            ),
            [transaction.payee, transaction.active],
          );
        },
      }),
      columnHelper.accessor((t) => t.recurrenceInterval, {
        id: 'recurrenceInterval',
        header: 'Interval',
        // width: 50,
        sortDescFirst: true,
        cell: (props): JSX.Element => {
          const transaction = props.row.original;
          return useMemo(
            () => (
              <Tag>
                <Text noOfLines={1}>{transaction.recurrenceInterval}</Text>
              </Tag>
            ),
            [transaction.recurrenceInterval],
          );
        },
      }),
      columnHelper.accessor((t) => t.transactions.length, {
        id: 'amount',
        header: 'Amount',
        // width: 50,
        sortDescFirst: true,
        cell: (props): JSX.Element => {
          const transaction = props.row.original;
          return useMemo(
            () => (
              <Text
                fontSize="l"
                fontWeight="semibold"
                color={transaction.active ? '' : disabledTextColor}
              >
                {(transaction.amount / 100).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </Text>
            ),
            [transaction.amount, transaction.active],
          );
        },
      }),
    ],
    [disabledTextColor],
  );

  return (
    <Flex flexDirection="column" flexGrow={1} maxW="100ch" gap={theme.space[4]}>
      <Heading as="h2" size="lg">
        Recurring Charges
      </Heading>
      <SharedTable
        columns={columns}
        data={recurringTransactions}
        error={error}
        hasMore={false}
        fetchMore={() => {
          /* do nothing */
        }}
        isInitialLoaded={isLoaded}
        isLoading={false}
        onRowClick={() => {
          /* do nothing */
        }}
        resourceNamePlural={'Recurring Transactions'}
        emptyComponent={<></>}
      />
    </Flex>
  );
}

export { RecurringTransactionTable };
